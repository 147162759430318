import { Card, Button } from "antd";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import image from "../../../assets/images/user-avatar.png";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
const UserCard = (props: any) => {
  const { Meta } = Card;
  const navigation = useNavigate();

  return (
    <Row style={{ marginTop: "10px" }}>
      {props?.data?.map((item: any, index: any) => (
        <Col key={index} md={3}>
          <Card
            hoverable
            cover={
              <img
                onClick={() =>
                  navigation("/user/user-profile", { state: { data: item } })
                }
                src={
                  item?.userDetails?.profileImage
                    ? item?.userDetails?.profileImage
                    : // : item?.shortListers?.profileImage
                    // ? item?.shortListers?.profileImage
                    item?.profileImage
                    ? item?.profileImage
                    : image
                }
              />
            }
          >
            <Meta
              className="userCard-box1"
              title={
                <div className="userCard-txt">
                  {item?.userDetails?.firstName
                    ? item?.userDetails?.firstName
                    : // : item?.shortListers?.firstName
                      // ? item?.shortListers?.firstName
                      item?.firstName}
                </div>
              }
              description={
                <div className="userCard-box2">
                  <Button
                    onClick={() => {
                      props.setSelectedUserId(item);
                      props.setOpen(true);
                    }}
                  >
                    chat here
                  </Button>
                  {item?.liked ? (
                    <IoMdHeart
                      size={30}
                      color="#6e2e92"
                      onClick={() =>
                        props.Delete(
                          item?.userDetails?.id
                            ? item?.userDetails?.id
                            : // : item?.shortListers?.id
                              // ? item?.shortListers?.id
                              item?.id
                        )
                      }
                    />
                  ) : (
                    <IoMdHeartEmpty
                      size={30}
                      onClick={() =>
                        props.InterestPost(
                          item?.userDetails?.id
                            ? item?.userDetails?.id
                            : // : item?.shortListers?.id
                              // ? item?.shortListers?.id
                              item?.id
                        )
                      }
                    />
                  )}
                </div>
              }
            />
          </Card>
          <br />
        </Col>
      ))}
    </Row>
  );
};

export default UserCard;
