import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import Footer from "../../components/footer";
import Header from "../../components/header/index";
import "./styles.scss";

const AboutUsScreen = () => {
  return (
    <>
      <Header color="#f4f4f4" />
      <br />
      <br />
      <br />

      <Container>
        <div className="aboutUsScreen-box3">
          <br />
          <div className="aboutUsScreen-txt2">About Us</div>
          <br />
          <div className="aboutUsScreen-txt1">
            <b>Ziwaj.com</b> is a premium matrimonial website for global
            Muslims. Our aim is to help Muslims to find their suitable life
            partner in an Islamic way. We do not support anything that's not
            Halal in Islam so Ziwaj.com is not a dating portal at all. Only
            matured Muslim men and women with an intention of marriage are
            allowed to register at Ziwaj.com
            <br />
            We are among the most trustworthy and technically advanced
            matrimonial portals. Ziwaj.com will make your matrimonial searches
            and online-match making a simple, easy and happy experience. No
            wonder as the number of memberships of Ziwaj.com.com is greatly
            increasing day by day.
          </div>
          <br />
          <div className="aboutUsScreen-txt2">Highlights of Ziwaj.com</div>
          <br />
          <div>
            <ul>
              <li className="aboutUsScreen-txt1">
                Global and wide set of profiles.
              </li>
              <li className="aboutUsScreen-txt1">
                Free and easy profile registration.
              </li>
              <li className="aboutUsScreen-txt1">
                Registered profiles are manually screened or validated to ensure
                that they meet the norms of our site.
              </li>
              <li className="aboutUsScreen-txt1">
                E-mail & mobile alerts when members contact each other.
              </li>
              <li className="aboutUsScreen-txt1">
                Safe and secure site. 100% privacy guaranteed
              </li>
            </ul>
          </div>
          <div className="aboutUsScreen-txt2">
            The aim of Ziwaj.com is clear
          </div>
          <br />
          <div className="aboutUsScreen-txt1">
            Understand the needs and concerns of singles Muslims through
            tireless research and analysis. Provide a pleasant, satisfying, and
            superior matchmaking experience to our customers while ensuring
            their privacy and security. <br />
            Give our customers complete control through easy to use interfaces
            and features that can help them identify, filter and contact
            potential partners.
            <br />
            Ziwaj.com is entirely committed to the creed of Islam. It is quite
            safe, secure and confidential. Our management team is highly Islamic
            and our employees are dedicated to make this online matrimonial
            channel the leader in the field of Muslim matrimony. Ziwaj.com
            fulfills all needs of today's Muslim singles that are truly
            searching for suitable and valuable ways to meet with their
            prospective life partners.
            <br />
            If you are urgently looking for life partner, we invite you to join
            Ziwaj.com and experience the happiness and overwhelming results to
            lead a success in finding a suitable Muslim life partner,
            inshaAllah.
          </div>
        </div>
        <br />
        <Row>
          <Col md={4}>
            <div className="aboutUsScreen-box1">
              <div className="aboutUsScreen-box2">
                <FiPhone />
              </div>
              <div>
                Helpline 24X7 <br /> +91 999 55 39 892
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="aboutUsScreen-box1">
              <div className="aboutUsScreen-box2">
                <AiOutlineMail />
              </div>
              <div>
                Send Your Queries
                <br />
                ziwaj@gmail.com.com
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="aboutUsScreen-box1">
              <div className="aboutUsScreen-box2">
                <IoLocationOutline />
              </div>
              <div>
                Head Office
                <br />
                Calicut , Kerala, India
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <br />
      <Footer />
    </>
  );
};

export default AboutUsScreen;
