import { Card, DatePicker, Form, Input, Select, notification } from "antd";
import { useEffect, useState } from "react";
import API from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { useForm } from "antd/es/form/Form";
import Loading from "../../components/loading";
import UserDataTable from "./dataTable";
import PageHeader from "../../components/pageHeaders";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserData = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [Notifications, contextHolder] = notification.useNotification();
  const user = useSelector((state: any) => state.User);
  let userData = user?.user?.id;
  const [data, setData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getActvites();
  }, [page, take]);

  const getActvites = async () => {
    try {
      setIsLoading2(true);
      let obj = {
        status: form.getFieldValue("status"),
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        gender: form.getFieldValue("gender"),
        page: page,
        take: take,
        id: Number(userData),
        payment: "Paid",
      };
      let api = API.USER_LIST;
      const response: any = await POST(api, obj);

      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const onView = (val: any) => {
    navigate(`/admin/userDetails/${val}`);
  };

  return (
    <div>
      {contextHolder}
      <PageHeader title={"User List"} backButton={true} loading={isLoading2}>
        <Form form={form} onValuesChange={getActvites}>
          <div className="PageHeaderBox">
            <Form.Item name={"gender"} noStyle>
              <Select
                placeholder="Select Gender"
                style={{ width: 150 }}
                allowClear
              >
                <Select.Option value={"Male"}>Male</Select.Option>
                <Select.Option value={"Female"}>Female</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={"query"} noStyle>
              <Input
                allowClear
                placeholder="Search Email and Name"
                style={{ width: 250 }}
                suffix={<CiSearch size={16} color="rgb(186, 186, 186)" />}
              />
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ width: 200 }} />
            </Form.Item>
          </div>
        </Form>
      </PageHeader>
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <UserDataTable
                data={data}
                onView={(item: any) => onView(item)}
                meta={meta}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default UserData;
