import { Col, Row } from "antd";
import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import image1 from "../../../assets/images/love2.jpg";
import image2 from "../../../assets/images/il_794xN.4674814930_qxka.jpg";
import image3 from "../../../assets/images/love3.jpg";
import image4 from "../../../assets/images/beautiful-black-woman-wedding-dress_1089311-81.jpg";
import "../styles.scss";

function BlockFour() {
  const sliderRef = useRef(null);
  const settings = {
    infinite: true,
    speed: 2000,
    arrow: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const data = [
    {
      image: image1,
      title: "Unlock Your Everlasting Love Here!",
    },
    {
      image: image2,
      title: "Unlock Your Everlasting Love Here!",
    },
    {
      image: image3,
      title: "Unlock Your Everlasting Love Here!",
    },
    {
      image: image4,
      title: "Unlock Your Everlasting Love Here!",
    },
  ];
  return (
    <>
      <div className="slickPage-Box1" id="slickPage">
        <div className="heading_text">
          Discover Your Perfect Match - Where Every Love Story Begins.
        </div>
      </div>
      <br />

      <Row className="second-fullpage g-0">
        <Col md={24} sm={24} xs={24} className="corosal">
          <div className="SlidersInFourth">
            <Slider ref={sliderRef} {...settings}>
              {data?.map((item, index) => (
                <div className="cardsInSecond" key={index}>
                  <div className="originalCards">
                    <div
                      className="imagediv"
                      style={{ backgroundImage: `url(${item.image})` }}
                    ></div>
                    <br />
                    <div className="slickPage-Txt3">{item.title}</div>
                    <br />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
      <br />
      <br />
    </>
  );
}
export default BlockFour;
