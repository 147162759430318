import { Button, Form, Input } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone, FiTwitter, FiYoutube } from "react-icons/fi";
import { LuUser2 } from "react-icons/lu";
import Footer from "../../components/footer";
import Header from "../../components/header/index";
import { POST } from "../../utils/apiCalls";
import "./styles.scss";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
const ContactScreen = () => {
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async () => {
    setIsLoading(true);
    try {
      let obj = "";
      let url = "";
      const res = await POST(url, obj);
      if (res) {
        console.log(res);
        setIsLoading(false);
      } else {
        console.log(res);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header color="#f4f4f4" />
      <br />
      <br />
      <br />
      <Container>
        <Form onFinish={onFinish}>
          <Row className="g-0">
            <Col md={6}>
              <div className="contactScreen-Box1">
                <div className="contactScreen-Text1">Contact Us</div>
                <br />
                <div className="contactScreen-Text2">
                  Connect with Ziwaj for Love and Support
                </div>
                <br />
                <div className="contactScreen-Text3">
                  <FiPhone size={20} /> &nbsp; Chat With Experts
                </div>
                <div className="contactScreen-Text4">
                  +91 9995539892 (WhatsApp)
                  <br />
                  +91 9995539892 (WhatsApp)
                </div>
                <br />
                <div className="contactScreen-Text3">
                  <AiOutlineMail size={20} /> &nbsp; Send Us Email
                </div>
                <div className="contactScreen-Text2">
                  Our Friendly team is here to help you. Send a mail to
                  <a href=""> Ziwaj@gmail.com</a>
                </div>
                <br />
                <div className="contactScreen-Text3">
                  Connect with us on social media
                </div>
                <br />
                <div>
                  <FaFacebookSquare size={30} /> &nbsp;
                  <FaInstagram size={30} /> &nbsp;
                  <FiTwitter size={30} /> &nbsp;
                  <FiYoutube size={30} />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="contactScreen-Box1">
                <div className="contactScreen-Text1">
                  Fill the Customer Support Form
                </div>
                <br />
                <div>
                  <Form.Item>
                    <Input
                      size="large"
                      placeholder="Full Name"
                      prefix={<LuUser2 color="grey" />}
                    />
                  </Form.Item>
                  <Form.Item name="">
                    <Input
                      size="large"
                      placeholder="E-mail"
                      type="email"
                      prefix={<AiOutlineMail color="grey" />}
                    />
                  </Form.Item>
                  <Form.Item name="">
                    <Input
                      size="large"
                      placeholder="Phone Number"
                      prefix={<FiPhone color="grey" />}
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item name="">
                    <TextArea rows={4} placeholder="Message" />
                  </Form.Item>
                  <br />
                  <Button
                    size="large"
                    type="primary"
                    block
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Send
                  </Button>
                </div>
              </div>
              <br />
            </Col>
          </Row>
        </Form>
      </Container>

      <Footer />
    </>
  );
};

export default ContactScreen;
