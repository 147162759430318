import { Col, Container, Row } from "react-bootstrap";
import image from "../../../assets/images/pexels-asad-photo-maldives-1268877.jpg";
import image2 from "../../../assets/images/premium_photo-1674197189331-8eba36abd709.jpg";
import "../styles.scss";

const BlockThree = () => {
  const images = [{ image1: image, image2: image2, image3: image }];

  return (
    <Container>
      <div className="heading_text">
        Unlock Your Happily Ever After - Where Love Finds You!
      </div>
      <br />
      <Row>
        {images[0] &&
          Object.values(images[0]).map((imgSrc, index) => (
            <Col md={4} key={index}>
              <div className="blockThree-box2">
                <img
                  src={imgSrc}
                  className="blockThree-img"
                  alt={`Image ${index + 1}`}
                />
              </div>
              <br />
              <br />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default BlockThree;
