import { Button, Drawer, Popover } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoListCircle } from "react-icons/io5";
import { SlHome } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import LOGO from "../../config/logo.png";
import "./styles.scss";

function Header(props: any) {
  const [open, setOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 60) {
        setHeaderColor(true);
      } else {
        setHeaderColor(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigationLinks = [
    { label: "Home", path: "/", icon: <SlHome /> },
    { label: "About Us", path: "/aboutUs", icon: <SlHome /> },
    { label: "Packages", path: "/account/1", icon: <SlHome /> },
    { label: "Contact Us", path: "/contact", icon: <SlHome /> },
  ];

  return (
    <div
      className="websiteHeader-Box1"
      style={{ backgroundColor: headerColor ? "#f4f4f4" : props?.color }}
    >
      <Container>
        <Row>
          <Col md={5} xs={6}>
            <div className="websiteHeader-Box2">
              <img
                className="img-header"
                onClick={() => navigate("/")}
                src={LOGO}
                alt=""
              />
            </div>
          </Col>
          <Col md={7} xs={6}>
            <div className="websiteHeader-Box3">
              {navigationLinks?.map(({ label, path }) => (
                <div key={label}>
                  <Popover
                    content={label}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <span
                      className="websiteHeader-Txt"
                      onClick={() => navigate(path)}
                    >
                      {label}
                    </span>
                  </Popover>
                </div>
              ))}
              <div>
                <Button type="primary" block onClick={() => navigate("/login")}>
                  Login
                </Button>
              </div>
            </div>
            <div className="webSiteHeader-Box4">
              <IoListCircle
                color="#875aa2"
                size={40}
                onClick={() => setOpen(true)}
              />
            </div>

            <Drawer
              placement="left"
              onClose={() => setOpen(false)}
              open={open}
              width={280}
            >
              <div className="websiteHeader-Box4">
                <div className="websiteHeader-Box5">
                  <img
                    className="websiteHeadet_Drawer_img-header"
                    onClick={() => navigate("/")}
                    src={LOGO}
                    alt=""
                  />
                </div>
                <hr />
                {navigationLinks?.map(({ label, path, icon }) => (
                  <div key={label} className="websiteHeadet_Drawer_box1">
                    <span
                      className="websiteHeader-Txt"
                      onClick={() => navigate(path)}
                    >
                      <span style={{ marginRight: "10px" }}>{icon}</span>{" "}
                      {label}
                    </span>
                  </div>
                ))}
                <br />
                <Button type="primary" onClick={() => navigate("/login")}>
                  Login
                </Button>
              </div>
            </Drawer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
