import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SlArrowDown } from "react-icons/sl";
import { POST } from "../../../utils/apiCalls";
import success from "../../../assets/images/success1.79f370b7.gif";
import API from "../../../config/api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slices/userSlice";
import educationCategory from "../helper/educationCategory.json";
import WorkCategories from "../helper/workCategory.json"
import "../styles.scss";
const StepThree = ({ onNext, onBack, update, userDetails }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state: any) => state.User);
  const [form] = Form.useForm();
  let logined_userid = user?.user?.data;
  let newId = user?.user;

  const dispatch = useDispatch();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      education_details:
        userDetails?.education_details || logined_userid?.education_details,
      work_firm: userDetails?.work_firm || logined_userid?.work_firm,
      work_place: userDetails?.work_place || logined_userid?.work_place,
      work_annualIncome:
        userDetails?.work_annualIncome || logined_userid?.work_annualIncome,
      partner_qualification:
        userDetails?.partner_qualification ||
        logined_userid?.partner_qualification,
      partner_employment:
        userDetails?.partner_employment || logined_userid?.partner_employment,
      family_motherName:
        userDetails?.family_motherName || logined_userid?.family_motherName,
      family_motherOccupation:
        userDetails?.family_motherOccupation ||
        logined_userid?.family_motherOccupation,
      partner_considerHandicaped:
        userDetails?.partner_considerHandicaped ||
        logined_userid?.partner_considerHandicaped,
      partner_expectation:
        userDetails?.partner_expectation || logined_userid?.partner_expectation,
      partner_considerSecond:
        userDetails?.partner_considerSecond ||
        logined_userid?.partner_considerSecond,
      education_category:
        userDetails?.education_category || logined_userid?.education_category,
      work_category:
        userDetails?.work_category || logined_userid?.work_category,
    });
  }, []);

  const onFinish = async (value: any) => {
    setIsLoading(true);
    try {
      let _id = null;
      if (userDetails?.id) {
        _id = userDetails?.id;
      }
      //  else if (newId?.id) {
      //   _id = newId?.id;
      // }
      else if (logined_userid?.id) {
        _id = logined_userid?.id;
      }

      let user_ID = null;
      if (userDetails?.userID) {
        user_ID = userDetails?.userID;
      } else if (newId?.userID) {
        user_ID = newId?.userID;
      } else if (logined_userid?.userID) {
        user_ID = logined_userid?.userID;
      }

      let obj = {
        userId: Number(user_ID),
        id: Number(_id),
        education_details: value?.education_details,
        work_firm: value?.work_firm,
        work_place: value?.work_place,
        work_annualIncome: value?.work_annualIncome,
        partner_qualification: value?.partner_qualification,
        partner_employment: value?.partner_employment,
        partner_considerSecond: value?.partner_considerSecond,
        partner_considerHandicaped: value?.partner_considerHandicaped,
        partner_expectation: value?.partner_expectation,
        work_category: value?.work_category,
        education_category: value?.education_category,
      };
      let url = API.REGISTER_STEP3;
      let res: any = await POST(url, obj);
      if (res.status === true) {
        setIsLoading(false);

        dispatch(login(res?.data));
        notification.success({
          message: res.message,
        });
        if (!update) {
          setIsModalOpen(true);
        }
      } else {
        notification.error({
          message: res.message,
        });
        setIsLoading(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      notification.error({
        message: "Something Went Wrong!",
      });
    }
  };

  return (
    <div>
      <>
        <br />
        <Card>
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError={true}
          >
            <Row>
              <Col md={6}>
                <div className="formLabel">Education and Work</div>
                <hr style={{ borderColor: "#875aa2" }} />
                <div className="formLabel">Education Category</div>
                <Form.Item
                  name={"education_category"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Education Category",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<SlArrowDown />}
                    style={{ width: "100%" }}
                    placeholder="Education Category"
                  >
                    {educationCategory.map((item:any, index:any) => (
                      <Select.Option key={index} value={item.value}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Partners Details</div>
                <hr style={{ borderColor: "#875aa2" }} />
                <div className="formLabel">Partners Qualification</div>
                <Form.Item name={"partner_qualification"}>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Partners Qualification"
                  />
                </Form.Item>{" "}
              </Col>
              <Col md={6}>
                <div className="formLabel">Education Details</div>
                <Form.Item name={"education_details"}>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="education Details"
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Partners Employment</div>
                <Form.Item name={"partner_employment"}>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Partners Employment"
                  />
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">Work Category</div>
                <Form.Item
                  name={"work_category"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Work Category",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<SlArrowDown />}
                    style={{ width: "100%" }}
                    placeholder="Work Category"
                  >
                    {WorkCategories.map((item:any, index:any) => (
                      <Select.Option key={index} value={item.value}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Working Place</div>
                <Form.Item name={"work_place"}>
                  <Input placeholder="Working Place" />
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">Annual Income</div>
                <Form.Item name={"work_annualIncome"}>
                  <Input placeholder="Annual Income" type="number" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Expectations About The Partner</div>
                <Form.Item name={"partner_expectation"}>
                  <Input placeholder="Expectations About The Partner" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Consider Handicaped</div>
                <Form.Item name={"partner_considerHandicaped"}>
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">Consider Second Marriage</div>
                <Form.Item name={"partner_considerSecond"}>
                  <Radio.Group>
                    <Radio value="yes" className="formLabel">
                      Yes
                    </Radio>
                    <Radio value="no" className="formLabel">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <br />
              <Row>
                <Col sm="6"></Col>
                <Col sm="3">
                  {update ? (
                    ""
                  ) : (
                    <Button block onClick={onBack} size="large">
                      Back
                    </Button>
                  )}
                </Col>
                <Col sm="3">
                  {update ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      loading={isLoading}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      loading={isLoading}
                      onClick={showModal}
                    >
                      Next
                    </Button>
                  )}
                </Col>
              </Row>
            </Row>
          </Form>
        </Card>
        <br />
        <br />
      </>
      {isModalOpen ? (
        <Modal footer={null} open={isModalOpen} onCancel={handleCancel}>
          <div className="Registration-StepThree-Box1">
            <img src={success} className="Registration-StepThree-image" />
          </div>
          <div className="Registration-StepThree-Box2">
            <div className="">
              <b>Welcome</b>
            </div>
            <div className="">Your User ID q14227</div>
            <div className=""> Your Registration is successful</div>
            <div className="">Please Upload your Photos</div>
            <div className="">More details check your email</div>
          </div>
          <br />
          <div className="Registration-txt">
            <Button type="primary" size="large" onClick={() => onNext()}>
              Next
            </Button>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default StepThree;
