import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../pageHeaders";
import PlanCard from "./planCard";
import { useParams } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
const plansData = [
  {
    title: "FREE/FOR WOMEN",
    price: 0,
    features: [
      "Unlimited Profile View",
      "Unlimited Messages",
      "Unlimited Interest Send",
      "Unlimited Photo View",
      "Unlimited Push Notifications",
      "Provide Website/Mobile App",
      "Malayalam/English Language Selection",
      "40 Contact View",
      "40 Whatsapp Message",
      "40 SMS",
      "4 Months Validity",
      "No Hidden Charges",
      "24x7 Call/Chat Support",
      "Only for Women",
    ],
    buttonText: "Pay Now",
  },
  {
    title: "UNLIMITED",
    price: 3000,
    features: [
      "Unlimited Days use",
      "Special Premium care",
      "Admin Profile Suggetions",
      "Unlimited Profile View",
      "Unlimited Messages",
      "Unlimited Interest Send",
      "Unlimited Photo View",
      "Unlimited Push Notifications",
      "Provide Website/Mobile App",
      "Malayalam/English Language Selection",
      "40 Contact View",
      "40 Whatsapp Message",
      "40 SMS",
      "4 Months Validity",
      "No Hidden Charges",
      "24x7 Call/Chat Support",
      "Only for Women",
      "Men/Women",
    ],
    duration: " / Unlimited",
    buttonText: "Pay Now",
    color: true,
  },
  {
    title: "PRO",
    price: 800,
    features: [
      "Unlimited Profile View",
      "Unlimited Messages",
      "Unlimited Interest Send",
      "Unlimited Photo View",
      "Unlimited Push Notifications",
      "Provide Website/Mobile App",
      "Malayalam/English Language Selection",
      "40 Contact View",
      "40 Whatsapp Message",
      "40 SMS",
      "4 Months Validity",
      "No Hidden Charges",
      "24x7 Call/Chat Support",
      "Only for Women",
    ],
    duration: " / 7 Months",
    buttonText: "Pay Now",
  },
  {
    title: "BASIC",
    price: 500,
    features: [
      "Unlimited Profile View",
      "Unlimited Messages",
      "Unlimited Interest Send",
      "Unlimited Photo View",
      "Unlimited Push Notifications",
      "Provide Website/Mobile App",
      "Malayalam/English Language Selection",
      "40 Contact View",
      "40 Whatsapp Message",
      "40 SMS",
      "4 Months Validity",
      "No Hidden Charges",
      "24x7 Call/Chat Support",
      "Only for Women",
    ],
    duration: " / 4 Months",
    buttonText: "Pay Now",
    isForWomen: true,
  },
];

function Account() {
  const data = useParams();

  return (
    <>
      {!data?.id ? (
        <PageHeader title={"Account"} backButton={true} />
      ) : (
        <>
          <Header color="#f4f4f4" />
          <br />
          <br />
          <br />
        </>
      )}
      <br />
      <Container>
        <div className="plansData-box1">
          <div className="plansData-txt">Packages</div>
          <br />
          <br />
          <Row className="plans g-0">
            {plansData.map((plan, index) => (
              <Col md={3}>
                <PlanCard key={index} plan={plan} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      {data?.id ? <Footer /> : ""}
    </>
  );
}

export default Account;
