import { useNavigate, useLocation } from "react-router-dom";
import LOGO from "../config/logo.png";
import Menu from "./menu.json";
import DynamicIcon from "./dynamicIcon";
import "./styles.scss";
function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <div className="sideBar-Box">
      <div className="sideBar-logo">
        <img src={LOGO} alt="" />
      </div>
      <div>
        <div className="sideBar-txt1">MAIN MENUS</div>
        {Menu?.slice(0, 3).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              <div>{item?.title}</div>
            </div>
          );
        })}
        <div className="sideBar-txt1">PREFENCES</div>
        {Menu?.slice(3, 5).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              <div>{item?.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
