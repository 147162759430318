import { Button, Form, Input, notification } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import { useState } from "react";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const handleReset = async (val: any) => {
    setLoading(true);
    try {
      const url = API.FORGOT_PASSWORD;
      const data = {
        email: val?.email,
      };
      const response: any = await POST(url, data);
      if (response.status) {
        notification.success({
          message: "Password Changing",
          description: "A mail has been sent to your Email.",
        });
      } else {
        notification.error({
          message: "Email is Incorrect",
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginScreen">
      <Container>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <div>
              <img className="logo" src={Logo} alt="Logo" />
            </div>
            <div className="EnterEmail-title EnterEmail-text">
              Forgot Password?
            </div>
            <div className="EnterEmail-desc EnterEmail-text">
              Enter the email address associated with your account, and we will
              send you a link to reset your password.
            </div>
            <Form onFinish={handleReset}>
              <Form.Item name="email">
                <Input size="large" type="email" placeholder="Enter Email" />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  className="loginScreen-Btn"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Reset
                </Button>
              </Form.Item>
              <div className="EnterEmail-textLast EnterEmail-text">
                Not a member yet?{" "}
                <Link to="/" style={{ textDecoration: "none" }}>
                  Register
                </Link>
              </div>
            </Form>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
