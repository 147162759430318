import { useEffect, useState } from "react";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { Card, notification } from "antd";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../components/pageHeaders";
import "./styles.scss";
import moment from "moment";
import avathar from "../../assets/images/user-avatar.png";
import Loading from "../../components/loading";

function UserViewScreen() {
  const [Notifications, contextHolder] = notification.useNotification();

  const [data, setData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    getDatas();
  }, []);
  const getDatas = async () => {
    try {
      setIsLoading(true);
      let api = API.USER_FIND_ONE + id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {contextHolder}
      <PageHeader title={"User Details"} backButton={true} />
      <br />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="screenBox">
          <Card>
            <Row>
              <Col md={12}>
                <div className="heding">Genaral Info</div>
              </Col>
              <br />
              <br />
              <Col md={3}>
                <Card
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="user_image"
                    src={data?.user_details?.profileImage || avathar}
                  />
                </Card>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={3}>
                    <div className="sub_heding">Name</div>
                    <div className="feald_div">
                      {data?.user_details?.firstName}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="sub_heding">gender</div>
                    <div className="feald_div">
                      {data?.user_details?.gender}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Date of Birth</div>
                    <div className="feald_div">
                      {moment(data?.user_details?.dob, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                      )}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="sub_heding">Height</div>
                    <div className="feald_div">
                      {data?.user_details?.height}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="sub_heding">Weight</div>
                    <div className="feald_div">
                      {data?.user_details?.weight}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Marital Status</div>
                    <div className="feald_div">
                      {data?.user_details?.maritalStatus}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Physical Status</div>
                    <div className="feald_div">
                      {data?.user_details?.physicalStatus}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">About</div>
                    <div className="feald_div">{data?.user_details?.about}</div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Qualification</div>
                    <div className="feald_div">
                      {data?.user_details?.qualification}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Job</div>
                    <div className="feald_div">{data?.user_details?.job}</div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">Email</div>
                    <div className="feald_div">{data?.user_details?.email}</div>
                  </Col>
                  <Col md={3}>
                    <div className="sub_heding">phone</div>
                    <div className="feald_div">{data?.user_details?.phone}</div>
                  </Col>
                  <Col md={12}>
                    <div className="sub_heding">Address</div>
                    <div className="feald_div">
                      {data?.contact_houseName}, {data?.contact_place}{" "}
                      {data?.contact_address1} {data?.contact_address2}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <br />
          <Card className="userTable">
            <div className="heding">Other Info</div>
            <br />
            <br />
            <table className="userTable">
              <tr>
                <th>Father Name</th>
                <th>Father Occupation</th>
                <th>Mother Name</th>
                <th>Mother Occupation</th>
                <th>Financial Status</th>
                <th>Annual Income</th>
              </tr>
              <tr>
                <td>{data.family_fatherName}</td>
                <td>{data.family_fatherOccupation}</td>
                <td>{data.family_motherName}</td>
                <td>{data.family_motherOccupation}</td>
                <td>{data.family_financialStatus}</td>
                <td>{data.work_annualIncome}</td>
              </tr>
            </table>
          </Card>
        </div>
      )}
    </div>
  );
}

export default UserViewScreen;
