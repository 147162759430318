import { Card, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import PageHeader from "../../components/pageHeaders";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import UserTransactionTable from "./dataTable";

const AdminTransactionScreen = () => {
  const [Notifications, contextHolder] = notification.useNotification();
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();

  const onView = (val: any) => {
    navigate(`/admin/userTransaction/${val}`);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);

  useEffect(() => {
    getActvites();
  }, []);

  const getActvites = async () => {
    try {
      setIsLoading2(true);

      let api = API.GET_ALL_TRANSACTION;
      const response: any = await GET(api, null);

      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        setData([]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeader
        title={"Transaction List"}
        backButton={true}
        loading={isLoading2}
      />
      <div className="screenBox">
        <Card>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="overFlow-Box1">
              <UserTransactionTable
                onView={(item: any) => onView(item)}
                data={data}
                onPageChange={(page: number, pageSize: number) => {
                  setIsLoading2(true);
                  setPage(page);
                  setTake(pageSize);
                }}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default AdminTransactionScreen;
