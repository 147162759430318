import { Card, message } from "antd";
import { ImCheckmark } from "react-icons/im";
import "./styles.scss";
import API from "../../config/api";
import { useDispatch, useSelector } from "react-redux";
import { POST } from "../../utils/apiCalls";
import { update } from "../../redux/slices/userSlice";
declare global {
  interface Window {
    Razorpay: any;
  }
}

const PlanCard = ({ plan }: any) => {
  const disaptch = useDispatch();
  const user: any = useSelector((val: any) => val.User.user);
  console.log(user, "transaction details edann nokk ");

  const onFinish = async (val: any) => {
    try {
      if (user?.data?.payment_status == "Paid") {
        message.error("Already paid the amount ");
      } else {
        var options = {
          key: "rzp_test_qrZersLsStO5Ja",
          key_secret: "fYjQFQvEzX0nRNBnYi0SWTLa",
          amount: val?.price * 100,
          currency: "INR",
          name: "Ziwaj",
          handler: async (response: any) => {
            let obj = {
              userID: Number(user?.data?.user_details?.id),
              payment_status: "Paid",
              payment_id: response?.razorpay_payment_id,
              category: val?.title,
              payment_amount: val?.price,
            };
            let url = API.CREATE_TRANSACTION;
            const res: any = await POST(url, obj);
            if (res.status) {
              let data = { ...res, token: user?.token };
              disaptch(update(data));
              message.success(res.message);
            } else {
              message.error(res.message);
            }

            alert(response.razorpay_payment_id);
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#6e2e92",
          },
        };
        var pay: any = new window.Razorpay(options);
        pay.open();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={plan.color ? "plan-card2" : "plan-card"}>
      <Card>
        <div className="plan-card-heard">{plan.title}</div>
        <div className="head-container">
          <div className="sub-head">₹ {plan.price}</div>
          <h3>{plan.duration}</h3>
        </div>
        <table>
          {plan.features.map((feature: any, index: any) => (
            <tr>
              <td>
                <ImCheckmark size={15} color="#d2d7e2" /> {feature}
              </td>
            </tr>
          ))}
        </table>

        {user?.data?.id && user?.data?.payment_status == "Paid" ? (
          <div className="pay_button" onClick={() => onFinish(plan)}>
            Pay Now
          </div>
        ) : (
          <div className="pay_button">Already Paid</div>
        )}
      </Card>
    </div>
  );
};

export default PlanCard;
