import { Routes, Route } from "react-router-dom";
// import { ConfigProvider } from "antd";
// import { useSelector } from "react-redux";
import AdminRoute from "./adminNavigation";
import LandingScreen from "./website/RegistrationScreen";
import LoginScreen from "./website/loginScreen";
import ContactScreen from "./website/contactScreen";
import UserScreen from "./userNavigation";
import HomeScreen from "./website/HomeScreen/index";
import ForgotPassword from "./website/loginScreen/forgottPassword/forgotPassword";
import ResetPassword from "./website/loginScreen/forgottPassword/ResetPassword";
import Account from "./components/myAccount";
import AboutUsScreen from "./website/aboutUs";
import "./App.scss";
import ProtectedRoute from "./utils/protectedRoute";
import { useSelector } from "react-redux";
function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <div>
      <Routes>
        <Route index element={<HomeScreen />} />;
        <Route path="/landing" element={<LandingScreen />} />;
        <Route path="/login" element={<LoginScreen />} />;
        <Route path="/contact" element={<ContactScreen />} />;
        <Route path="/forgotPassword" element={<ForgotPassword />} />;
        <Route path="/resetPassword/:id" element={<ResetPassword />} />;
        <Route path="/account/:id" element={<Account />} />;
        <Route path="/aboutUs" element={<AboutUsScreen />} />;
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
            <AdminRoute />
           </ProtectedRoute>
          }
        />
        <Route
          path="/user/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
            <UserScreen />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
