import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Store, PersistedStore } from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeObj from "./theme"
import { ConfigProvider } from 'antd';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider theme={ThemeObj}>
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={PersistedStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  </ConfigProvider>
);
reportWebVitals();
