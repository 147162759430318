import { useEffect, useState } from "react";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { Card, notification } from "antd";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../components/pageHeaders";
import moment from "moment";
import avathar from "../../assets/images/user-avatar.png";
import Loading from "../../components/loading";
import "./styles.scss";

function UserTransactionViewScreen() {
  const [Notifications, contextHolder] = notification.useNotification();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const getDatas = async () => {
    try {
      let api = API.GET_ALL_TRANSACTION + "/" + id;
      const response: any = await GET(api, null);
      if (response) {
        setData(response);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDatas();
  }, []);
  let datas = data?.transaction_payment;

  const personalDetailsFields = [
    { label: "Name", value: datas?.firstName },
    { label: "Gender", value: datas?.gender },
    { label: "Date of Birth", value: moment(datas?.dob).format("YYYY-MM-DD") },
    { label: "Height", value: datas?.height },
    { label: "Weight", value: datas?.weight },
    { label: "Marital Status", value: datas?.maritalStatus },
    { label: "Physical Status", value: datas?.physicalStatus },
    { label: "About", value: datas?.about },
    { label: "Qualification", value: datas?.qualification },
    { label: "Job", value: datas?.job },
    { label: "Email", value: datas?.email },
    { label: "Phone", value: datas?.phone },
  ];

  const transactionDetailsFields = [
    { label: "Payment Status", value: data?.payment_status },
    { label: "Category", value: data?.category },
    { label: "Payment id", value: data?.payment_id },
    { label: "Payment Amount", value: data?.payment_amount },
  ];

  return (
    <div>
      {contextHolder}
      <PageHeader title={"Transaction Details"} backButton={true} />
      <br />
      <div className="screenBox">
        {isLoading ? (
          <Loading />
        ) : (
          <Card>
            <Row>
              <Col md={12}>
                <div className="userTransactionViewScreen-txt1">
                  Transaction Details{" "}
                </div>
              </Col>
              <br />
              <br />
              <Col md={3}>
                <Card>
                  <img
                    className="userTransactionViewScreen-img"
                    src={datas?.profileImage || avathar}
                  />
                </Card>
              </Col>
              <Col md={9}>
                <div>Personal Details</div>
                <hr />
                <Row>
                  {personalDetailsFields.map((item: any) => {
                    return (
                      <Col md={3}>
                        <div className="userTransactionViewScreen-txt2">
                          {item?.label}
                        </div>
                        <div className="userTransactionViewScreen-txt3">
                          {item?.value}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col md={3}></Col>
              <Col>
                <br />
                <div>Transaction Details</div>
                <hr />
                <Row>
                  {transactionDetailsFields.map((item: any) => {
                    return (
                      <Col md={3}>
                        <div className="userTransactionViewScreen-txt2">
                          {item?.label}
                        </div>
                        <div className="userTransactionViewScreen-txt3">
                          {item?.value}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Card>
        )}

        <br />
      </div>
    </div>
  );
}

export default UserTransactionViewScreen;
