import { Table } from "react-bootstrap";

function DashboardTable() {
  const data = [
    {
      id: 1,
      title: "xxxx",
      email: "xxxxxxxxxxxx",
      amount: "xxxx",
    },
    {
      id: 2,
      title: "xxxx",
      email: "xxxxxxxxxxxx",
      amount: "xxxx",
    },
    {
      id: 3,
      title: "xxxx",
      email: "xxxxxxxxxxxx",
      amount: "xxxx",
    },
    {
      id: 4,
      title: "xxxx",
      email: "xxxxxxxxxxxx",
      amount: "xxxx",
    },
    {
      id: 4,
      title: "xxxx",
      email: "xxxxxxxxxxxx",
      amount: "xxxx",
    },
  ];
  return (
    <>
      <div className="dashboard-Txt1">XXXXXX</div>
      <br />
      <Table hover size="small">
        <thead>
          <tr>
            <th className="booking-th">xxxxx</th>
            <th className="booking-th">xxxxx</th>
            <th className="booking-th">xxxxx</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any) => {
            return (
              <>
                <tr>
                  <td className="booking-td">{item?.title}</td>
                  <td className="booking-td">{item?.email}</td>
                  <td className="booking-td">{item?.amount}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default DashboardTable;
