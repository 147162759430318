import { Tabs, TabsProps } from "antd";
import StepOne from "../../website/RegistrationScreen/components/stepOne";
import { useEffect, useState } from "react";
import StepTwo from "../../website/RegistrationScreen/components/StepTwo";
import StepThree from "../../website/RegistrationScreen/components/stepThree";
import { Container } from "react-bootstrap";
import PageHeader from "../../components/pageHeaders";
import StepFour from "../../website/RegistrationScreen/components/stepFour";
import { ImFilePicture } from "react-icons/im";
import { LiaUserSolid } from "react-icons/lia";
import { FaIdCard } from "react-icons/fa6";
import { BiDetail } from "react-icons/bi";
import { MdKey } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { useSelector } from "react-redux";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import Loading from "../../components/loading";
import UserEmail from "../email";
import UserPassword from "../password";

const UserMyProfile = () => {
  const user = useSelector((state: any) => state.User);
  const [userDetails, setUserDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  let userData = user?.user?.data?.user_details;


  useEffect(() => {
    GetUserById();
  }, []);

  const GetUserById = async () => {
    let _id = null;
    if (userData?.id) {
      _id = userData?.id;
    } else if (userDetails?.userID) {
      _id = userDetails?.userID;
    }
    try {
      let url = API.USER_FIND_ONE + _id;
      const res: any = await GET(url, "");
      if (res) {
        setUserDetails(res?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [update, setUpdate] = useState<any>(1);
  const onChange = (key: string) => {
    setUpdate(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Login",
      children: loading ? (
        <Loading />
      ) : (
        <StepOne update={update} userDetails={userDetails} />
      ),
      icon: <LiaUserSolid size={20} />,
    },
    {
      key: "2",
      label: "Details",
      children: <StepTwo update={update} userDetails={userDetails} />,
      icon: <FaIdCard size={20} />,
    },
    {
      key: "3",
      label: "Qualification",
      children: <StepThree update={update} userDetails={userDetails} />,
      icon: <BiDetail size={20} />,
    },
    {
      key: "4",
      label: "Pictures",
      children: <StepFour update={update} userDetails={userDetails} />,
      icon: <ImFilePicture />,
    },
    {
      key: "5",
      label: "Change Email",
      children: <UserEmail />,
      icon: <MdOutlineMail size={20} />,
    },
    {
      key: "6",
      label: "Change Password",
      children: <UserPassword />,
      icon: <MdKey size={20} />,
    },
  ];

  return (
    <>
      <PageHeader title={"Profile"} backButton={true} />
      <div className="screenBox">
        <Container>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange}></Tabs>
        </Container>
      </div>
    </>
  );
};

export default UserMyProfile;
