import { useNavigate, useLocation } from "react-router-dom";
import DynamicIcon from "./dynamicIcon";
import Menu from "./menu.json";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slices/userSlice";
import image from "../assets/images/user-avatar.png";
import "./styles.scss";
function SideBar() {
  const user = useSelector((state: any) => state.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let userData = user?.user?.data?.user_details;

  return (
    <div className="sideBarUser-Box">
      <br />
      <div className="sideBarUser-Box2">
        <div className="sideBarUser-Box3">
          {userData?.profileImage ? (
            <img
              className="sideBarUser-img"
              src={userData?.profileImage}
              alt=""
            />
          ) : (
            <img className="sideBarUser-img" src={image} alt="" />
          )}
        </div>
        <br />
        <div>
          <b>{userData?.firstName}</b>
        </div>
      </div>

      <div>
        <div className="sideBarUser-txt1">MAIN MENUS</div>
        {Menu?.slice(0, 8).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBarUser-item-user active"
                  : "sideBarUser-item-user"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              {/* <hr color="#875aa2" /> */}
              <div>{item?.title}</div>
              {/* <hr color="#875aa2" /> */}
            </div>
          );
        })}

        <div className="sideBarUser-txt1">PREFENCES</div>
        {Menu?.slice(8, 11).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => {
                if (item.id === "11") {
                  dispatch(login([]));
                  navigate(item?.navigate);
                } else {
                  navigate(item?.navigate);
                }
              }}
              className={
                location.pathname === item?.navigate
                  ? "sideBarUser-item-user active"
                  : "sideBarUser-item-user"
              }
            >
              <DynamicIcon icon={item?.icon} />

              <div style={{ marginLeft: 20 }} />

              <div>{item?.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
