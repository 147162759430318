import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../config/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import EmailLogin from "./login/emailLogin";
import PhoneLogin from "./login/phoneLogin";
import "./styles.scss";

function LoginScreen() {
  const [loginMethod, setLoginMethod] = useState("email");

  return (
    <Container>
      <div className="loginScreen">
        <Row className="g-0">
          <Col sm={4}></Col>
          <Col sm={4}>
            <div className="loginScreen-Box1">
              <img src={Logo} alt="" />
            </div>
            <div className="loginScreen-txt1">Login</div>

            {loginMethod === "email" ? <EmailLogin /> : <PhoneLogin />}
            <br />
            <div className="d-flex justify-content-between">
              <div
                className="form_label"
                onClick={() =>
                  setLoginMethod(loginMethod === "email" ? "phone" : "email")
                }
              >
                {loginMethod === "email" ? "Phone Login ?" : "Email Login ?"}
              </div>
              {loginMethod === "email" ? (
                <div>
                  <Link
                    to={"/forgotPassword"}
                    style={{ textDecoration: "none", color: "#875aa2" }}
                  >
                    Forgot Password ? &#128273;
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col sm={4}></Col>
        </Row>
      </div>
    </Container>
  );
}

export default LoginScreen;
