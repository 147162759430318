const API = {
  // BASE_URL: "http://localhost:8018/",
  BASE_URL: "https://server.bairuhatech.com/ziwaj-server/",

  USER_LOGIN: "users/admin/login",

  GET_ALL_USER: "users",
  VERIFY_MAIL: "users/verifyEmail/",
  USER_STEP_1_UPDATE: "users/update/",
  USER_UPDATE_EMAIL: "users/updateEmail/",
  USER_DETAILS_STEP_2_UPDATE: "userDetails/update/secondStep/",
  REGISTER_STEP1: "users/register/step1",
  REGISTER_STEP2: "userDetails/register/step2",
  REGISTER_STEP3: "userDetails/register/step3",
  REGISTER_STEP4: "userDetails/register/step4",
  S3_IMAGE_UPLOAD: "img_compress/add",
  USER_LIST: "users/list",
  USER_PROFILE: "users/profile/",
  USER_BY_MATCHES: "users/matches",
  USER_LIST_BY_MATCH: "users/listByMatch",
  USER_MESSAGE_LIST_BY_USERID: "conversation/listMessageByUserId",
  USER_FIND_ONE: "userDetails/findone/",
  UPDATE_PASSWORD: "users/updatePassword/",
  FORGOT_PASSWORD: "users/forgotPassword",
  CHANGE_PASSWORD: "users/changePassword",
  // message
  CREATE_MESSAGE: "message/create",
  GET_MESSAGE: "message/",
  GET_CONVERSATION: "conversation",

  // interest
  POST_INTERST: "intrest/create",
  GET_INTEREST: "intrest/liked",
  DISLIKE: "intrest/delete/",

  // shortlists
  ADD_SHORTLIST: "shortlist/create",
  GET_SHORTLIST: "shortlist/shorlists",
  REMOVE_SHORTLIST: "shortlist/delete/",

  // profile visitors
  GET_PROFILE_VISITORS: "visitors/visited",

  CREATE_TRANSACTION: "transaction",
  GET_ALL_TRANSACTION: "transaction",

  // login======

  VERIFYOTP: "sms/verify-otp",
  REQUESTOTP: "sms/request-otp",
  LOGIN_PHONE: "users/phone-login",
};
export default API;
