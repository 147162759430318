import { Card } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdHeart } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/user-avatar.png";
import PageHeader from "../../components/pageHeaders";
import API from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import WarningScreen from "../component/warning/warning";
import "./styles.scss";
const UserNotificationScreen = () => {
  const user = useSelector((state: any) => state.User);
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [likedData, setLikedData] = useState<any>([]);
  const [visitorsData, setVisitorsData] = useState<any>([]);
  const [notificationShown, setNotificationShown] = useState(false);
  const [conversation, setConversation] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(8);
  const [form] = useForm();

  let userid = user?.user?.data?.userID;
  useEffect(() => {
    getLiked();
    getVisitors();
    getActvites();
  }, []);

  const getLiked = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_INTEREST;
      const response: any = await GET(url, {});
      if (response.status) {
        setLikedData(response.data);
        setIsLoading(false);
        if (response?.data?.length > likedData.length && !notificationShown) {
          setNotificationShown(true);
          showNotification("You Have New Likes!");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getVisitors = async () => {
    try {
      const url = API.GET_PROFILE_VISITORS;
      const response: any = await GET(url, {});

      if (response.status) {
        setVisitorsData(response.data);
        setIsLoading(false);
        if (
          response?.data?.length > visitorsData?.length &&
          !notificationShown
        ) {
          setNotificationShown(true);
          showNotification("You Have New Visitors!");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getActvites = async () => {
    try {
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: take,
        user_id: Number(userid),
        payment: paymentStatus == "Paid" ? "Paid" : "",
      };

      let api = API.USER_MESSAGE_LIST_BY_USERID;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setConversation(response);
      } else {
        setConversation([]);
      }
    } catch (err) {}
  };

  const showNotification = (message: string) => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification(message);
        }
      });
    }
  };

  let paymentStatus = user?.user?.data?.user_details?.payment_status;
  return (
    <div>
      <PageHeader title={"Notification"} backButton={true} />
      <br />
      <Container>
        {!paymentStatus ? (
          <WarningScreen />
        ) : (
          <>
            <Row>
              <div>Profile Liked List</div>
              {likedData?.length === 0 ? (
                <Col md={3}>
                  <Card>
                    <div>No likes yet</div>
                  </Card>
                </Col>
              ) : (
                likedData.map((item: any) => (
                  <Col key={item.id} md={3}>
                    <Card>
                      <div className="userNotificationScreen-box1">
                        <div className="userNotificationScreen-box2">
                          <img
                            onClick={() =>
                              navigation("/user/user-profile", {
                                state: { data: item },
                              })
                            }
                            className="userNotificationScreen-img"
                            src={item?.userDetails?.profileImage || avatar}
                            alt="Profile"
                          />
                        </div>
                        <div onClick={() => navigation("/user/user-interest")}>
                          {item?.userDetails?.firstName} Liked Your Profile
                          &nbsp;
                          <IoMdHeart size={20} color="red" />
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
            <br />
            <Row>
              <div>Profile Visitors List</div>

              {visitorsData?.length === 0 ? (
                <Col md={3}>
                  <Card>
                    <div>No Visitors yet</div>
                  </Card>
                </Col>
              ) : (
                visitorsData?.map((item: any) => {
                  return (
                    <Col md={3}>
                      <Card>
                        {likedData.length == 0 ? (
                          <div>No Visiters yet</div>
                        ) : (
                          <div className="userNotificationScreen-box1">
                            <div className="userNotificationScreen-box2">
                              <img
                                onClick={() =>
                                  navigation("/user/user-profile", {
                                    state: { data: item },
                                  })
                                }
                                className="userNotificationScreen-img"
                                src={item?.userDetails?.profileImage || avatar}
                              />
                            </div>
                            <div
                              onClick={() =>
                                navigation("/user/user-profileVisitors")
                              }
                            >
                              {item?.userDetails?.firstName} Visited Your
                              Profile
                            </div>
                          </div>
                        )}
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
            <br />
            <Row>
              <div>Message List</div>

              {visitorsData?.length === 0 ? (
                <Col md={3}>
                  <Card>
                    <div>No Messagers yet</div>
                  </Card>
                </Col>
              ) : (
                conversation?.data?.map((item: any) => {
                  let user =
                    item?.from_details?.id === userid
                      ? { ...item?.to_details, conversationid: item?.id }
                      : {
                          ...item?.from_details,
                          conversationid: item?.id,
                        };
                  return (
                    <Col md={3}>
                      <Card>
                        {conversation.length === 0 ? (
                          <div>No Messagers yet</div>
                        ) : (
                          <div className="userNotificationScreen-box1">
                            <div className="userNotificationScreen-box2">
                              <img
                                onClick={() =>
                                  navigation("/user/user-profile", {
                                    state: { data: { id: user?.id } },
                                  })
                                }
                                className="userNotificationScreen-img"
                                src={user?.profileImage || avatar}
                              />
                            </div>
                            <div
                              onClick={() => navigation("/user/user-message")}
                            >
                              {user?.firstName} Sended a Message To You
                            </div>
                          </div>
                        )}
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default UserNotificationScreen;
