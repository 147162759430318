import { Button } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import firstImage from "../../../assets/images/pexels-photo-1109561.webp";
import secondimage from "../../../assets/images/hannahwayphotography.com-116-scaled.webp";
import thirdimage from "../../../assets/images/bc17af0599e91248ff2958677adb60fb.jpg";

const images = [firstImage, secondimage, thirdimage];

const BlockFive = () => {
  return (
    <Container>
      <Row className="g-0">
        <Col md={4}>
          <div className="blockFive-box1">
            <div className="blockFive-txt1">
              LET'S PLAN YOUR <br />
              WEDDING
            </div>
            <br />
            <div className="blockFive-txt2">
              Welcome and open yourself to your <br />
              truest love this year with us! with the Release Process
            </div>
            <br />
            <br />
            <Button type="primary" block style={{ width: "50%" }}>
              More
            </Button>
          </div>
        </Col>
        <Col md={8}>
          <Row>
            {images.map((imageSrc, index) => (
              <Col key={index} md={4}>
                <div className="blockFive-box2">
                  <img src={imageSrc} className="blockFive-img" alt="" />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default BlockFive;
