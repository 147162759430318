import { Button, Form, Input, notification } from "antd";
import { InputOTP } from "antd-input-otp";
import { useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrefixSelector from "../../../components/prefixSelecter";
import API from "../../../config/api";
import { login } from "../../../redux/slices/userSlice";
import { POST } from "../../../utils/apiCalls";

function PhoneLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [verification, setverification] = useState(false);
  const [error, setError] = useState<any>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [token, setToken] = useState();
  const [number, setNumber] = useState();
  const [code, setCode] = useState();
  const [userData, setUserData] = useState<any>();

  const requestOTP = async (data: any) => {
    try {
      setIsLoading(true);
      setIsLoading2(true);
      let reqObj = {
        code: data?.code || code,
        phoneNumber: data?.phone || number,
        type: "login",
      };
      setNumber(data?.phone || number);
      setCode(data?.code || code);
      let url = API.REQUESTOTP;
      let res: any = await POST(url, reqObj);
      if (res.status) {
        setUserData(res);
        setToken(res.token);
        setverification(true);
        setError(false);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  const verifyOtp = async (data: any) => {
    try {
      setIsLoading(true);
      setIsLoading2(true);
      const url = API.VERIFYOTP;
      let reqBody = {
        otp: data?.otp?.join(""),
        token: token,
        phoneNumber: number,
        user_id: userData?.data?.id,
      };
      let res: any = await POST(url, reqBody);

      if (userData?.token == "admin") {
        notification.success({
          message: "Successfully Logged in",
        });
        navigate("/admin/home");
      } else {
        if (res?.data?.status) {
          notification.success({
            message: "Successfully Logged in",
          });
          let allData = { ...res?.data?.data, token: res?.data?.token };

          dispatch(login(allData));
          navigate("/user/userHomeScreen");
        } else {
          setError(true);
          notification.error({
            message: res.message || "Otp is Incorrect",
          });
        }
      }
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <div className="PhoneLogin">
      {contextHolder}
      <br />
      <div>
        <div className="loginScreen-txt2">
          {verification
            ? `Enter Otp to Your mobile number ${number}`
            : `${"Enter Your Mobile Number"}`}
        </div>
        <Form
          onFinish={verification ? verifyOtp : requestOTP}
          initialValues={{ code: "+91" }}
        >
          {verification ? (
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: `${"Input_6_digit"}`,
                },
              ]}
            >
              <InputOTP autoFocus inputType="numeric" length={6} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: `${"Enter Your Phone Number"}`,
                  },
                ]}
              >
                <Input
                  addonBefore={<PrefixSelector />}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={"Enter_Phone_Number"}
                  type="number"
                />
              </Form.Item>
            </>
          )}
          <Button
            loading={isLoading}
            block
            size="large"
            type="primary"
            htmlType="submit"
          >
            {verification ? "Login" : "Get OTP"}
          </Button>
          {error ? (
            <>
              <div className="LoginScreen-errortxt">
                <BiErrorCircle />
                &nbsp;
                {error} {"Try_antoher_way"}
              </div>
              <Button
                loading={isLoading2}
                block
                size="large"
                style={{ height: 40 }}
                onClick={requestOTP}
              >
                Resend_OTP
              </Button>
            </>
          ) : null}
        </Form>
        <hr />
      </div>
    </div>
  );
}
export default PhoneLogin;
