import { Col, Container, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import Cards from "./component/cards";
import { Card } from "antd";
import DashboardTable from "./component/dataTable";
import DashboardGraphChart from "./component/graph";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import { CgGirl } from "react-icons/cg";

import "./styles.scss";
function Dashboard() {
  const [data, setData] = useState<any>();
  const [totalCount, setTotalCount] = useState();
  const [maleCount, setMaleCount] = useState();
  const [femaleCount, setFemaleCount] = useState();
  const getAllUsers = async () => {
    try {
      let url = API.GET_ALL_USER;
      const response: any = await GET(url, "");
      if (response) {
        setData(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (data) {
      const total = data?.length;
      const male = data?.filter(
        (user: { gender: any }) => user?.gender === "Male"
      ).length;
      const female = data?.filter(
        (user: { gender: any }) => user?.gender === "Female"
      ).length;
      setTotalCount(total);
      setMaleCount(male);
      setFemaleCount(female);
    }
  }, [data]);

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <br />
      <Container>
        <Row>
          <Col md={3}>
            <Cards
              icon={<FaUserCircle />}
              label={"Total Mens"}
              value={maleCount}
            />
          </Col>
          <Col md={3}>
            <Cards
              icon={<CgGirl />}
              label={"Total Womens"}
              value={femaleCount}
            />
          </Col>
          <Col md={3}>
            <Cards
              icon={<FaUserCircle />}
              label={"Total Users"}
              value={totalCount}
            />
          </Col>
          <Col md={3}>
            <Cards icon={<FaUserCircle />} label={"xxxx"} value={"xxxx"} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={8}>
            <Card>
              <DashboardGraphChart />
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <DashboardTable />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
