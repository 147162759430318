import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { MdDashboard } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { FaCircleUser } from "react-icons/fa6";
import { AiOutlineHome } from "react-icons/ai";
import { IoNotificationsOutline } from "react-icons/io5";
import { PiStarThin } from "react-icons/pi";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlineAccountBalance } from "react-icons/md";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { TbMessage } from "react-icons/tb";
import { IoMdHeartEmpty } from "react-icons/io";
import { TbUsersPlus } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";

function DynamicIcon(props: any) {
  type IconName =
    | "MdDashboard"
    | "TbBrandBooking"
    | "CgUnavailable"
    | "FaCircleUser"
    | "AiOutlineHome"
    | "IoNotificationsOutline"
    | "PiStarThin"
    | "IoEyeOutline"
    | "MdOutlineMail"
    | "MdOutlineAccountBalance"
    | "RiLogoutCircleRLine"
    | "IoIosSearch"
    | "IoIosSearch"
    | "TbMessage"
    | "IoMdHeartEmpty"
    | "TbUsersPlus"
    | "CgProfile"

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 25, color = "#0a2f35" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      MdDashboard: MdDashboard,
      TbBrandBooking: TbBrandBooking,
      CgUnavailable: CgUnavailable,
      FaCircleUser: FaCircleUser,
      AiOutlineHome: AiOutlineHome,
      IoNotificationsOutline: IoNotificationsOutline,
      PiStarThin: PiStarThin,
      IoEyeOutline: IoEyeOutline,
      MdOutlineMail: MdOutlineMail,
      MdOutlineAccountBalance: MdOutlineAccountBalance,
      RiLogoutCircleRLine: RiLogoutCircleRLine,
      IoIosSearch: IoIosSearch,
      TbMessage: TbMessage,
      IoMdHeartEmpty: IoMdHeartEmpty,
      TbUsersPlus: TbUsersPlus,
      CgProfile:CgProfile
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable";
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} />;
  }

  return <Icon iconName={props.icon} size={props.size} />;
}

export default DynamicIcon;
