import Footer from "../../components/footer";
import Header from "../../components/header";
import BlockFive from "./component/blockFive";
import BlockFour from "./component/blockFour";
import BlockOne from "./component/blockOne";
import BlockThree from "./component/blockThree";
import BlockTwo from "./component/blockTwo";

const HomeScreen = () => {
  return (
    <div>
      <Header color={"transparent"} />
      <BlockOne />
      <br />
      <br />
      <BlockFive />
      <BlockThree />
      <br />
      <BlockTwo />
      <BlockFour />

      <Footer />
    </div>
  );
};

export default HomeScreen;
