import { Drawer, Form, Input, Pagination, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import PageHeader from "../../components/pageHeaders";
import API from "../../config/api";
import { DELETE, GET, POST } from "../../utils/apiCalls";
import UserChatDrawerTwo from "../component/chat/chat-drawer2";
import UserCard from "../component/userCard";
import WarningScreen from "../component/warning/warning";

const UserMyMatches = () => {
  const [form] = useForm();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(8);
  const [meta, setMeta] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: any) => state.User);

  let userData = user?.user?.data?.user_details?.id;
  let paymentStatus = user?.user?.data?.user_details?.payment_status;

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    matches(page, take);
  }, []);

  const matches = async (p: any, t: any) => {
    setIsLoading(true);

    try {
      let text = form.getFieldValue("query");
      let url = text
        ? API.USER_BY_MATCHES + `?order=DESC&page=${p}&take=${t}&query=${text}`
        : API.USER_BY_MATCHES + `?order=DESC&page=${p}&take=${t}`;

      const response: any = await GET(url, "");
      setData(response?.data);
      setMeta(response?.meta);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const InterestPost = async (id: any) => {
    try {
      const url = API.POST_INTERST;
      const obj = {
        likedId: parseInt(id),
      };
      const response: any = await POST(url, obj);
      if (response.status) {
        setData((prevData: any) =>
          prevData.map((item: any) =>
            item?.id === id ? { ...item, liked: true } : item
          )
        );
        message.success("Liked");
      }
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };

  const Delete = async (id: any) => {
    try {
      const url = API.DISLIKE + id;
      const response: any = await DELETE(url);
      if (response.status) {
        setData((prevData: any) =>
          prevData.map((item: any) =>
            item?.id === id ? { ...item, liked: false } : item
          )
        );
        message.success("Disliked");
      }
    } catch (err: any) {
      console.log("err");
    }
  };
  const pagination = (page: any, take: any) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    matches(page, take);
  };

  return (
    <>
      {!paymentStatus ? (
        <>
          <PageHeader title={"My Matches"} backButton={true} />
          <WarningScreen />
        </>
      ) : (
        <>
          <PageHeader title={"My Matches"} backButton={true}>
            <Form form={form} onValuesChange={() => matches(page, take)}>
              <div className="PageHeaderBox">
                <Form.Item name={"query"} noStyle>
                  <Input
                    size="large"
                    allowClear
                    placeholder="Search Name And Email"
                    style={{ width: 250 }}
                    suffix={<CiSearch size={16} color="rgb(186, 186, 186)" />}
                  />
                </Form.Item>
              </div>
            </Form>
          </PageHeader>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="screenBox">
              <UserCard
                data={data}
                Delete={(id: any) => Delete(id)}
                InterestPost={(id: any) => InterestPost(id)}
                setSelectedUserId={(item: any) => setSelectedUserId(item)}
                setOpen={setOpen}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  defaultCurrent={meta.page}
                  total={meta.itemCount}
                  pageSize={meta.take ? meta.take : 0}
                  onChange={(page, take) => pagination(page, take)}
                  pageSizeOptions={[10, 20, 50]}
                />
              </div>

              {open && (
                <Drawer
                  width={500}
                  onClose={onClose}
                  open={open}
                  closable={false}
                >
                  <div style={{ padding: "10px" }}>
                    <UserChatDrawerTwo
                      onClose={onClose}
                      closable={false}
                      userData={userData}
                      id={selectedUserId?.conversaction?.id}
                      search={"search"}
                      selectedUserId={selectedUserId}
                      userName={user}
                    />
                  </div>
                </Drawer>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserMyMatches;
