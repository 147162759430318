import "./styles.scss";
import loading from "../../assets/images/loding.gif";

const Loading = () => {
  return (
    <div className="Loading-box">
      <img style={{height: '200px'}} src={loading} />
      <div> &nbsp; Loading . . . </div>
    </div>
  );
};

export default Loading;
