import { Card, Form, Input, Image, message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { DELETE, GET, POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { useEffect, useState } from "react";
import moment from "moment";
import PageHeader from "../../components/pageHeaders";
import { useLocation, useNavigate } from "react-router-dom";
import image from "../../assets/images/user-avatar.png";
import { AiOutlineDelete } from "react-icons/ai";


const Profile = () => {
  const data = useLocation();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [details, setDetails] = useState<any>();


  const profileId = data?.state?.data?.id
    ? data?.state?.data?.id
    : data?.state?.data?.userDetails?.id;
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const url = API.USER_PROFILE + profileId;
      const response: any = await GET(url, {});
      if (response.status) {
        setDetails(response?.data);
      }
    } catch (error) {
      message.error("something went wrong");
    }
  };

  const AddToShortList = async () => {
    try {
      const obj = {
        ShortlistId: parseInt(profileId)
      }
      const url = API.ADD_SHORTLIST;
      const response: any = await POST(url, obj);
      if (response.status) {
        message.success(response.message)
        getDetails();
      }
    } catch (err) {
      message.error("something went wrong")
    }
  }

  const RemoveShortList = async (id:any) => {
    try {
      const url = API.REMOVE_SHORTLIST + id;
      const response: any = await DELETE(url);
      if (response.status) {
       message.success("Removed From Shortlist");
       getDetails();
      }
    } catch (err: any) {
      console.log("err");
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      firstName: details?.firstName,
      middleName: details?.middleName,
      lastName: details?.lastName,
      gender: details?.gender,
      dob: moment(details?.dob).format("MMMM Do YYYY"),
      height: details?.height,
      weight: details?.weight,
      maritalStatus: details?.maritalStatus,
      physicalStatus: details?.physicalStatus,
      about: details?.about,
      profileImage: details?.profileImage,
      age: details?.age,
      regional_caste: details?.regional_caste,
    });
  });

  return (
    <>
      <PageHeader title={"User Profile"} backButton={true} />
      <div className="screenBox">
        <Card>
          <Form layout="vertical" form={form} scrollToFirstError={true}>
            <Row>
              <Col md={6}>
                <Col md={12}>
                  <div className="formLabel">Full Name</div>
                  <Form.Item
                    name={"firstName"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Full Name",
                      },
                    ]}
                  >
                    <Input readOnly placeholder="Full Name" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <div className="formLabel">Religion/Caste</div>
                  <Form.Item
                    name={"regional_caste"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Religion/Caste",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Col>
              <Col md={6}>
                <div className="StepOne_box1">
                  <Image
                    style={{ width: "200px", height: "200px" }}
                    src={details?.profileImage ? details?.profileImage : image}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="formLabel">Height (cm)</div>
                <Form.Item
                  name={"height"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Height",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Age</div>
                <Form.Item name="age">
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">Weight (cm)</div>
                <Form.Item
                  name={"weight"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Weight",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">Physical Status</div>
                <Form.Item
                  name={"physicalStatus"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Physical Status",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={6}>
                <div className="formLabel">Marital Status</div>
                <Form.Item
                  name={"maritalStatus"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Marital Status",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col md={6}>
                {" "}
                <div className="formLabel">Date of Birth</div>
                <Form.Item
                  name={"dob"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date of Birth",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col md={6}>
                <div className="formLabel">About the Candidate</div>
                <Form.Item
                  name={"about"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter About the Candidate",
                    },
                  ]}
                >
                  <TextArea readOnly rows={2} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <br/>
                <br/>
                {details?.Shortlisted ?
                 <Button onClick={()=> RemoveShortList(details?.id)} size="large" type="primary" style={{ width: "100%" }}>
                  Remove From Short List &nbsp; <AiOutlineDelete style={{marginBottom:"5px"}} color="white" size={22}/>
                </Button>
                : 
                <Button onClick={AddToShortList} size="large" style={{ width: "100%" }} type="primary">
                  Add to Short List
                </Button>
                }
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Profile;
