import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { MdDashboard } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { FaCircleUser } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";

function DynamicIcon(props: any) {
  type IconName =
    | "MdDashboard"
    | "TbBrandBooking"
    | "CgUnavailable"
    | "FaCircleUser"
    | "GiTakeMyMoney";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 25, color = "#0a2f35" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      MdDashboard: MdDashboard,
      TbBrandBooking: TbBrandBooking,
      CgUnavailable: CgUnavailable,
      FaCircleUser: FaCircleUser,
      GiTakeMyMoney: GiTakeMyMoney,
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable";
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} />;
  }

  return <Icon iconName={props.icon} size={props.size} />;
}

export default DynamicIcon;
