import { Button, Card, Form, Input, Select, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { POST, PUT } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useEffect, useState } from "react";
import { login, updateStep } from "../../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { SlArrowDown } from "react-icons/sl";
import PrefixSelector from "../../../components/prefixSelecter";

const StepTwo = ({ onNext, onBack, update, userDetails }: any) => {
  const [loding, setLoding] = useState(false);
  const [form] = useForm();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.User);

  let first_step_userid = user?.user?.data?.user_details;
  let logined_userid = user?.user?.data;
  let step_finish = user.steps[1];

  const onFinish = async (value: any) => {
    try {
      setLoding(true);
      let _id = null;
      // let check these logined_userid dont want======>

      if (logined_userid?.id) {
        _id = logined_userid?.id;
      } else if (first_step_userid?.id) {
        _id = first_step_userid?.id;
      } else if (userDetails?.id) {
        _id = userDetails?.id;
      }
      let url =
        update || step_finish
          ? API.USER_DETAILS_STEP_2_UPDATE + _id
          : API.REGISTER_STEP2;
      let obj = {
        userID: Number(_id),
        contact_phone: Number(value?.contact_phone),
        contact_community: "string",
        contact_address2: "string",
        contact_houseName: value?.contact_houseName,
        contact_place: value?.contact_place,
        family_fatherName: value?.family_fatherName,
        family_fatherOccupation: value?.family_fatherOccupation,
        family_motherName: value?.family_motherName,
        family_motherOccupation: value?.family_motherOccupation,
        family_financialStatus: value?.family_financialStatus,
        family_noOfMarriedBro: value?.family_noOfMarriedBro,
        family_noOfUnMarriedBro: value?.family_noOfUnMarriedBro,
        family_noOfMarriedSis: value?.family_noOfMarriedSis,
        family_noOfUnMarriedSis: value?.family_noOfUnMarriedSis,
        contact_email: value?.contact_email,
        contact_address1: value?.contact_address1,
        email_verify: 1,
        work_category: "",
        education_category: "",
      };
      let res: any;

      if (update || step_finish) {
        res = await PUT(url, obj);
      } else {
        res = await POST(url, obj);
      }
      if (res.status) {
        notification.success({
          message: res.message,
        });

        dispatch(login(res?.data));
        dispatch(updateStep(1));
        onNext();
      } else {
        setLoding(false);
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      setLoding(false);
      notification.error({
        message: "Not Create",
      });
    }
  };

  const financialStatus = [
    {
      id: 1,
      value: "Below Moderate",
    },
    {
      id: 2,
      value: "Moderate",
    },
    {
      id: 3,
      value: "Above Moderate",
    },
    {
      id: 4,
      value: "Rich",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      contact_houseName:
        userDetails?.contact_houseName || logined_userid?.contact_houseName,
      contact_place:
        userDetails?.contact_place || logined_userid?.contact_place,
      contact_community:
        userDetails?.contact_community || logined_userid?.contact_community,
      contact_address1:
        userDetails?.contact_address1 || logined_userid?.contact_address1,
      contact_address2:
        userDetails?.contact_address2 || logined_userid?.contact_address2,
      contact_phone:
        userDetails?.contact_phone || logined_userid?.contact_phone,
      contact_email:
        userDetails?.contact_email || logined_userid?.contact_email,
      family_fatherName:
        userDetails?.family_fatherName || logined_userid?.family_fatherName,
      family_fatherOccupation:
        userDetails?.family_fatherOccupation ||
        logined_userid?.family_fatherOccupation,
      family_motherName:
        userDetails?.family_motherName || logined_userid?.family_motherName,
      family_motherOccupation:
        userDetails?.family_motherOccupation ||
        logined_userid?.family_motherOccupation,
      family_financialStatus:
        userDetails?.family_financialStatus ||
        logined_userid?.family_financialStatus,
      family_noOfMarriedBro:
        userDetails?.family_noOfMarriedBro ||
        logined_userid?.family_noOfMarriedBro,
      family_noOfUnMarriedBro:
        userDetails?.family_noOfUnMarriedBro ||
        logined_userid?.family_noOfUnMarriedBro,
      family_noOfMarriedSis:
        userDetails?.family_noOfMarriedSis ||
        logined_userid?.family_noOfMarriedSis,
      family_noOfUnMarriedSis:
        userDetails?.family_noOfUnMarriedSis ||
        logined_userid?.family_noOfUnMarriedSis,
      code: "91",
    });
  }, []);

  return (
    <>
      <br />
      <Card>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError={true}
        >
          <Row>
            <Col md={6}>
              <div className="formLabel">Contact Information</div>
              <hr style={{ borderColor: "#875aa2" }} />
              <div className="formLabel">House Name</div>
              <Form.Item
                name={"contact_houseName"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter House Name",
                  },
                ]}
              >
                <Input placeholder="House Name" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Family Details</div>
              <hr style={{ borderColor: "#875aa2" }} />
              <div className="formLabel">Father's Name</div>
              <Form.Item
                name={"family_fatherName"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Father's Name",
                  },
                ]}
              >
                <Input placeholder="Father's Name" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Place</div>
              <Form.Item
                name={"contact_place"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Place",
                  },
                ]}
              >
                <Input placeholder="Place" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Father's Occupation</div>
              <Form.Item name="family_fatherOccupation">
                <Input placeholder="Father's Occupation" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Mother's Name</div>
              <Form.Item
                name={"family_motherName"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Mother's Name",
                  },
                ]}
              >
                <Input placeholder="Mother's Name" />
              </Form.Item>
            </Col>

            <Col md={6}>
              <div className="formLabel">Mother's Occupation</div>
              <Form.Item name={"family_motherOccupation"}>
                <Input placeholder="Mother's Occupation" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Financial Status</div>
              <Form.Item name={"family_financialStatus"}>
                <Select
                  suffixIcon={<SlArrowDown />}
                  style={{ width: "100%" }}
                  placeholder="Financial Status"
                >
                  {financialStatus?.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Contact Email</div>
              <Form.Item name={"contact_email"}>
                <Input placeholder="Contact Email" type="email" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Phone</div>
              <Form.Item
                name={"contact_phone"}
                rules={[
                  {
                    required: true,
                    message: "Please Type Your Contact Number",
                  },
                  {
                    pattern: /^\d{10}$/, // Enforce exactly 10 digits
                    message: "Phone number must be exactly 10 digits",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter your 10-digit Contact number"
                  addonBefore={<PrefixSelector />}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Number of Sisters</div>
              <Row>
                <Col sm={4}>
                  <Form.Item name={"family_noOfMarriedSis"}>
                    <Input placeholder="Married" type="number" />
                  </Form.Item>
                </Col>
                <Col sm={4}>
                  <Form.Item name={"family_noOfUnMarriedSis"}>
                    <Input placeholder="Unmarried" type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div className="formLabel">Religion/Caste</div>
              <Form.Item
                name={"Caste"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Religion/Caste",
                  },
                ]}
              >
                <Select
                  suffixIcon={<SlArrowDown />}
                  style={{ width: "100%" }}
                  placeholder="Caste"
                >
                  <Select.Option key={"MAPPILA"}>MAPPILA</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <div className="formLabel">Number of Brothers</div>
              <Row>
                <Col sm="4">
                  <Form.Item name={"family_noOfMarriedBro"}>
                    <Input placeholder="Married" type="number" />
                  </Form.Item>
                </Col>
                <Col sm="4">
                  <Form.Item name={"family_noOfUnMarriedBro"}>
                    <Input placeholder="Unmarried" type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <div className="formLabel">Address</div>
              <Form.Item name={"contact_address1"}>
                <TextArea placeholder="Address" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <br />
              <br />
              <Row>
                <Col md={6}>
                  {update ? (
                    ""
                  ) : (
                    <Button block onClick={onBack} size="large">
                      Back
                    </Button>
                  )}
                </Col>
                <Col md={6}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={loding}
                  >
                    {update ? "Update" : "Next"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      <br />
      <br />
    </>
  );
};

export default StepTwo;
