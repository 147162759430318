import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { notification } from "antd";
import { VscVerifiedFilled } from "react-icons/vsc";
import { TbXboxX } from "react-icons/tb";
import Loading from "../../components/loading";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import { useSelector } from "react-redux";
const UpdateEmail = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: any) => state.User);
  // const [userDetails, setUserDetails] = useState<any>();

  let userData = user?.user;

  const emailVerify = async () => {
    // let _id = null;
    // if (userData?.userID) {
    //   _id = userData?.userID;
    // } else if (userDetails?.userID) {
    //   _id = userDetails?.userID;
    // }

    let url = API.VERIFY_MAIL + userData?.id;
    try {
      const response: any = await GET(url, null);
      if (response) {
        setData(response);
        notification.success({
          message: "Updated email",
          description: "Your email has been updated successfully.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update email. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    emailVerify();
    // GetUserById();
  }, []);

  return (
    <div className="screenBox">
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : data ? (
          <div>
            <VscVerifiedFilled color="#18a762" size={50} />
            Email Verified
          </div>
        ) : (
          <div>
            <TbXboxX color="red" size={50} />
            Email Not Verified
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateEmail;
