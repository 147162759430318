import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { Button, Form, Input, notification } from "antd";
import { useState } from "react";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const onFinish = async (value: any) => {
    setLoading(true);
    const { password, confirm } = value;
    // const passwordRegex =
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^()])[A-Za-z\d@$!%*#?&^()]{8,}$/;

    // if (!passwordRegex.test(password)) {
    //   return alert(
    //     "Password does not meet requirements. Please follow the password criteria."
    //   );
    // }
    if (!confirm) {
      return alert("Please confirm new password");
    }
    if (password !== confirm) {
      return alert("Password does not match");
    }
    try {
      const url = API.CHANGE_PASSWORD;
      const data = {
        password: value?.password,
        token: id,
      };
      const response: any = await POST(url, data);
      if (response.status) {
        notification.success({
          message: "Updated Password",
          description: "Your Password has been updated successfully.",
        });
        navigation("/login");
      }
    } catch (error) {
      console.error(error, "something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginScreen">
      <Container>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <div>
              <img className="logo" src={Logo} alt="Logo" />
            </div>
            <div className="EnterEmail-title EnterEmail-text">
              Reset Password?
            </div>
            <div className="EnterEmail-desc EnterEmail-text">
              Enter the email address associated with your account, and we will
              send you a link to reset your password.
            </div>
            <Form onFinish={onFinish}>
              <div className="formLabel">New Password</div>
              <Form.Item
                hasFeedback
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Enter the new password"
                />
              </Form.Item>
              <div className="formLabel">Confirm Password</div>
              <Form.Item
                hasFeedback
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Enter the confirm password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  className="loginScreen-Btn"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Forgot Password
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
