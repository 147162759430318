import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import UserHomeScreen from "../user/home";
import SideBarUser from "./sideBar";
import UserNotificationScreen from "../user/notification";
import UserMyMatches from "../user/myMatches";
import UserSearch from "../user/search";
import UserMyProfile from "../user/myProfile";
import Account from "../components/myAccount";
import UserMessageScreen from "../user/message";
import UserInterest from "../user/interest";
import UserShortlist from "../user/shortlist";
import UserProfileVisitors from "../user/profileVisitors";
import UpdateEmail from "../user/emailVerify";
import "./styles.scss";
import Profile from "../user/profile";
function UserScreen() {
  const { Sider } = Layout;
  return (
    <Layout className="AdminRoute">
      <Sider width={260} className="userRoute-Sider">
        <SideBarUser />
      </Sider>
      <Layout className="UserScreen-box1">
        <div className="AdminRoute-box">
          <Routes>
            <Route path="/userHomeScreen" element={<UserHomeScreen />} />
            <Route
              path="/userNotificationScreen"
              element={<UserNotificationScreen />}
            />
            <Route path="/user-myMatches" element={<UserMyMatches />} />
            <Route path="/user-myProfile" element={<UserMyProfile />} />
            <Route path="/user-search" element={<UserSearch />} />
            <Route path="/user-account" element={<Account />} />
            <Route path="/user-message" element={<UserMessageScreen />} />
            <Route path="/user-interest" element={<UserInterest />} />
            <Route path="/user-shortlist" element={<UserShortlist />} />
            <Route path="/user-profile" element={<Profile />} />
            <Route
              path="/user-profileVisitors"
              element={<UserProfileVisitors />}
            />
            <Route path="/user-updateEmail" element={<UpdateEmail />} />
          </Routes>
        </div>
      </Layout>
    </Layout>
  );
}

export default UserScreen;
