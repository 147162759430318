import { Button, Card, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import uploadImg from "../../../assets/images/pictures.93a8987a.png";
import ImagePicker from "../../../components/imagePicker";
import { useState } from "react";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login } from "../../../redux/slices/userSlice";
import "../styles.scss";
const StepFour = ({ userDetails, update, onBack }: any) => {
  const [toggle, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.User);
  let allUerData = user?.user?.data;

  const [cardData, setCardData] = useState([
    { image: userDetails?.image1 },
    { image: userDetails?.image2 },
    { image: userDetails?.image3 },
    { image: userDetails?.image4 },
    { image: userDetails?.image5 },
  ]);

  const onFinish = async () => {
    setIsLoading(true);
    try {
      let _id = null;
      if (userDetails?.id) {
        _id = userDetails?.id;
      } else if (allUerData?.id) {
        _id = allUerData?.id;
      }
      let user_ID = null;
      if (userDetails?.userID) {
        user_ID = userDetails?.userID;
      } else if (allUerData?.userID) {
        user_ID = allUerData?.userID;
      }
      let obj = {
        userId: Number(user_ID),
        id: Number(_id),
        image1: cardData[0]?.image || null,
        image2: cardData[1]?.image || null,
        image3: cardData[2]?.image || null,
        image4: cardData[3]?.image || null,
        image5: cardData[4]?.image || null,
      };

      let url = API.REGISTER_STEP4;
      let res: any = await POST(url, obj);
      let newData = {
        ...res?.data,
        step2: 2,
        step1: 1,
        step3: 3,
        step4:4,
        token: res?.token
      };
      dispatch(login(newData));

      if (res.status) {
        notification.success({
          message: res.message,
        });
        setIsLoading(false);
        if (!update) {
          navigate("/user/userHomeScreen");
        }
      } else {
        notification.success({
          message: res?.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: "Something Went Wrong!",
      });
    }
  };

  const handleImageUpload = (imageData: any) => {
    const updatedCardData = [...cardData];
    updatedCardData[image].image = imageData;
    setCardData(updatedCardData);
  };

  return (
    <div>
      <br />
      <Card>
        <Row>
          {cardData.map((card, index) => {
            return (
              <Col md={4} key={index}>
                <Card>
                  <div
                    onClick={() => {
                      toggleModal(true);
                      setImage(index);
                    }}
                  >
                    <div className="upload_image-Box1">
                      {card?.image ? (
                        <img
                          src={card?.image}
                          className="upload_Image"
                          alt={`Uploaded Image ${index}`}
                        />
                      ) : (
                        <img src={uploadImg} className="upload_Image" />
                      )}
                    </div>
                    <br />
                    <div className="Registration-txt">Upload My Photo</div>
                  </div>
                </Card>
                <br />
              </Col>
            );
          })}
        </Row>
        <br />
        <Row>
          <Col sm="6"></Col>
          <Col sm="3">
            {update ? (
              ""
            ) : (
              <Button htmlType="submit" block size="large" onClick={onBack}>
                Back
              </Button>
            )}
          </Col>
          <Col sm="3">
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              onClick={onFinish}
              loading={isLoading}
            >
              {update ? "Update" : "Submit & Finish"}
            </Button>
          </Col>
        </Row>
      </Card>
      <br />
      <br />
      <ImagePicker
        onImageUpdated={(data: any) => handleImageUpload(data)}
        open={toggle}
        modalClose={() => toggleModal(false)}
      />
    </div>
  );
};

export default StepFour;
