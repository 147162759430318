import { Area } from "@ant-design/charts";
import { Card } from "antd";
const DashboardGraphChart = (props: any) => {
  const data = [
    { year: "2000", value: 1 },
    { year: "2010", value: 2 },
    { year: "2020", value: 3 },
    { year: "2030", value: 2 },
    { year: "2040", value: 5 },
    { year: "2050", value: 5 },
  ];
  const gradientConfig = {
    type: "linear",
    stops: [
      { offset: 0, color: "#6e2e92" }, // Start color
      { offset: 1, color: "#FFFFFF" }, // End color
    ],
    global: false, // Indicates local gradient
  };
  const gradientString = `l(90) 0:${gradientConfig.stops[0].color} 1:${gradientConfig.stops[1].color}`;
  const config = {
    data: data,
    xField: "year",
    yField: "value",
    line: {
      style: {
        stroke: "#6e2e92",
      },
    },
    smooth: true,
    style: {
      fill: gradientString,
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
      cursor: "pointer",
      strokeColor: "#6e2e92",
    },
    point: {
      size: 4,
      style: {
        fill: "#6e2e92",
        stroke: "#6e2e92",
        lineWidth: 2,
      },
    },
    height: 200,
    tooltip: false,
  };
  return (
    <Card title={"Analytics"}>
      <Area {...config} />
    </Card>
  );
};
export default DashboardGraphChart;
