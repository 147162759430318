import { Container } from "react-bootstrap";
import PageHeader from "../../components/pageHeaders";
import WarningScreen from "../component/warning/warning";
import "./styles.scss";
function UserHomeScreen() {
  return (
    <div style={{ height: "100vh" }}>
      <PageHeader title={"Home"} backButton={true} />
      <Container>
        <WarningScreen />
      </Container>
    </div>
  );
}

export default UserHomeScreen;
