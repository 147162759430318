import { Form } from "antd";
import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { HiMiniUserCircle } from "react-icons/hi2";
import InputEmoji from "react-input-emoji";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import profile from "../../../assets/images/user-avatar.png";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import MessageBubble from "./bubble";
import Loading from "../../../components/loading";
import "../styles.scss";

const UserChatDrawerTwo = (props: any) => {
  const [socket, setSocket] = useState<any>(null);
  const [messages, setMessages] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const user = useSelector((state: any) => state.User);
  const [text, setText] = useState("");

  useEffect(() => {
    // Connect to WebSocket server
    const newSocket = io("ws://localhost:8018");
    setSocket(newSocket);

    // Listen for incoming messages
    newSocket.on("message", (message: any) => {
      setMessages((prevMessages: any) =>
        prevMessages ? [...prevMessages, message] : [message]
      );
    });

    return () => {
      // Clean up WebSocket connection when component unmounts
      newSocket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    let Obj = {
      sender_Id: Number(props?.userData),
      receiver_Id: Number(props?.selectedUserId?.id),
      message: text,
      conversation_id: Number(props?.id),
      user_Id: 0,
      read: "string",
      recieve: "string",
      receiver_name: props?.selectedUserId?.firstName,
      sender_name: user?.user?.data?.user_details?.firstName,
    };

    if (socket) {
      socket.emit("message", Obj);
      setText("");
    }
  };

  const getMessage = async () => {
    setLoading(true);
    try {
      let url = API.GET_MESSAGE + props?.id;
      const response = await GET(url, null);
      if (response) {
        setMessages(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    getMessage();
  }, [props?.id]);

  return (
    <div>
      <Form>
        <div className="userChatDrawer-box1">
          <div className="userChatDrawer-box2">
            {!props?.search ? (
              ""
            ) : (
              <FaArrowLeftLong
                color="grey"
                size={25}
                onClick={props?.onClose}
              />
            )}
            <div>
              {props?.selectedUserId && props?.selectedUserId?.profileImage ? (
                <img
                  className="userChatDrawer-Img"
                  src={props?.selectedUserId?.profileImage}
                />
              ) : props?.message && !props?.selectedUserId ? (
                ""
              ) : (
                <img className="userChatDrawer-Img" src={profile} />
              )}
            </div>

            <div>{props?.selectedUserId?.firstName}</div>
          </div>
        </div>

        <div
          className={
            props?.message ? "userChatDrawer-box3" : "userChatDrawer-box33"
          }
        >
          <div className={props?.message ? "message-Box" : "message-Box2"}>
            <div className="userChatDrawer-txt1">
              {props?.message && !props?.selectedUserId
                ? "start the conversation"
                : ""}
            </div>

            {loading ? (
              <Loading />
            ) : (
              messages?.map((message: any, index: any) => {
                const isCurrentUser = message?.sender_Id === props?.userData;
                const profileImage = props?.selectedUserId?.profileImage;
                return (
                  <MessageBubble
                    key={index}
                    text={message?.message}
                    position={isCurrentUser ? "right" : "left"}
                    image={
                      profileImage ? (
                        <img
                          className="userChatDrawer-Img2"
                          src={profileImage}
                          alt="Profile"
                        />
                      ) : (
                        <HiMiniUserCircle size={22} color="grey" />
                      )
                    }
                  />
                );
              })
            )}
          </div>
        </div>
        <div className="userChatDrawer-box4">
          {!props?.id && !props?.search ? (
            ""
          ) : (
            <Form.Item>
              <InputEmoji
                value={text}
                onChange={setText}
                cleanOnEnter
                placeholder="Type a message"
                shouldReturn={true}
                shouldConvertEmojiToImage={false}
                onEnter={sendMessage}
              />
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  );
};

export default UserChatDrawerTwo;
