import { Button, Form, Input, message } from "antd";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import { login } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";

const EmailLogin = () => {
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (val: any) => {
    setIsloading(true);
    try {
      const obj = {
        email: val.email,
        password: val.password,
      };
      const url = API.USER_LOGIN;
      const response: any = await POST(url, obj);

      if (!response.status) {
        message.error("User Not Found");
        setIsloading(false);
        return;
      }

      const userDetails = response?.data?.data.user_details;

      if (userDetails?.type === "admin") {
        message.success("Successfully Logged in");
        let allData = {...response.data, ...response.token}
        dispatch(login(allData));
        navigate("/admin/home");
      } else {
        if (userDetails?.email_verify === 1) {
          message.success("Successfully Logged in");
          let allData = {...response.data , token:response.token}
          dispatch(login(allData));
          navigate("/user/userHomeScreen");
        } else {
          message.error("User Not Found");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <Form onFinish={onFinish}>
        <label className="formLabel">Email or Username</label>
        <Form.Item
          name={"email"}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input size="large" type="email" />
        </Form.Item>
        <label className="formLabel">Password</label>
        <Form.Item
          name={"password"}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <br />
        <Button
          block
          size="large"
          htmlType="submit"
          loading={isLoading}
          className="loginScreen-Btn"
        >
          Login
        </Button>
      </Form>
    </div>
  );
};

export default EmailLogin;
