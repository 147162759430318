import { Alert, Button, Space } from "antd";
import { PiWarningCircleLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const WarningScreen = (props: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <h3>{props?.title}</h3>
      <br />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Alert
          description={
            <div>
              <p>
                <PiWarningCircleLight size={40} />
                &nbsp; <b>Please wait for your account verification.</b>
              </p>
              <p>
                Your account verification will be soon. Please wait for it.
                Profile activation can be done only if you upload your profile
                picture. Those who find any difficulty in adding your profile,
                send your profile picture to our WhatsApp, and we will upload it
                for you. Our service is free for women. Men should pay. For the
                payment, press the button below.
              </p>
              <Button
                type="primary"
                onClick={() => navigate("/user/user-account")}
              >
                Pay Now
              </Button>
            </div>
          }
          type="info"
        />
      </Space>
    </div>
  );
};

export default WarningScreen;
