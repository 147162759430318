import { Button, Card, Form, Input, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const UserEmail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: any) => state.User);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const url = API.USER_UPDATE_EMAIL;
      const data = {
        email: values?.email,
        userId: Number(user?.user?.id),
      };

      const response: any = await POST(url, data);
      setLoading(false);
      if (response) {
        notification.success({
          message: "Email Changing",
          description: "A mail has been sent to your Email.",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error, "something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      email: user?.user?.data?.user_details?.email,
    });
  }, []);

  return (
    <div>
      <br />
      <Card>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <Col md={6}>
              <label className="formLabel">Change Email</label>
              <br />
              <Form.Item name="email">
                <Input placeholder="Enter The Email" type="email" />
              </Form.Item>
              <br />
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default UserEmail;
