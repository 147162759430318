import { useState } from "react";
import { Steps } from "antd";
import { Row, Col } from "react-bootstrap";
import StepOne from "./components/stepOne";
import StepTwo from "./components/StepTwo";
import Footer from "../../components/footer";
import Header from "../../components/header/index";
import StepThree from "./components/stepThree";
import StepFour from "./components/stepFour";
import { LiaUserSolid } from "react-icons/lia";
import { FaIdCard } from "react-icons/fa";
import { ImFilePicture } from "react-icons/im";
import { BiDetail } from "react-icons/bi";

function LandingScreen() {
  const [current, setCurrent] = useState(0);
  const items: any = [
    {
      title: "Register",
      icon: <LiaUserSolid color={current === 0 ? "#875aa2" : "grey"} />,
      content: <StepOne onNext={() => setCurrent(1)} />,
    },
    {
      title: "Details",
      icon: <FaIdCard color={current === 1 ? "#875aa2" : "grey"} />,
      content: (
        <StepTwo onNext={() => setCurrent(2)} onBack={() => setCurrent(0)} />
      ),
    },
    {
      title: "Qualification",
      icon: <BiDetail color={current === 2 ? "#875aa2" : "grey"} />,
      content: (
        <StepThree onNext={() => setCurrent(3)} onBack={() => setCurrent(1)} />
      ),
    },
    {
      title: "Pictures",
      icon: <ImFilePicture color={current === 3 ? "#875aa2" : "grey"} />,
      content: <StepFour onBack={() => setCurrent(2)} />,
    },
  ];

  return (
    <div>
      <Header color={"#f4f4f4"} />
      <br />
      <br />
      <br />
      <br />
      <Row className="g-0">
        <Col xs={12} sm={12} md={1}></Col>
        <Col xs={12} sm={12} md={3}>
          Image
        </Col>
        <Col xs={12} sm={12} md={7}>
          <Steps current={current} items={items} />
          <div>{items[current].content}</div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
      <Footer />
    </div>
  );
}

export default LandingScreen;
