import { Card, Input, Form, Button, Space, Alert, notification } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { useSelector } from "react-redux";

const UserPassword = () => {
  const user = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(false);
  const onFinish = async (val: any) => {
    try {
      setLoading(true);
      const url = API.UPDATE_PASSWORD + user?.user?.id;
      const data = {
        password: val?.old_password,
        password_new: val?.password3,
        userid: user?.user?.id,
      };

      const response: any = await POST(url, data);
      if (response.status) {
        notification.success({
          message: "Updated Password",
          description: "Your Password has been updated successfully.",
        });
      } else {
        notification.error({
          message: "Current Password Is Incorrect !!",
        });
      }
    } catch (error) {
      notification.error({
        message: "something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <br />
      <Card>
        <Form onFinish={onFinish}>
          <Row>
            <Col md={5}>
              <label className="formLabel">Current Password</label>
              <br />
              <Form.Item name="old_password">
                <Input placeholder="Enter the password" />
              </Form.Item>
              <label className="formLabel">New Password</label>
              <br />
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input.Password placeholder="Enter the new password" />
              </Form.Item>
              <label className="formLabel">Confirm Password</label>
              <br />
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("confirm") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Enter the confirm password" />
              </Form.Item>
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Button
                    type="primary"
                    loading={loading}
                    block
                    htmlType="submit"
                  >
                    Update
                  </Button>
                </Col>
                <Col md={2}></Col>
              </Row>
            </Col>
            <Col md={7}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={<b>PASSWORD REQUIREMENTS</b>}
                  description={
                    <div>
                      <p>
                        TO CREATE A NEW PASSWORD, YOU HAVE TO MEET ALL OF THE
                        FOLLOWING REQUIREMENTS:
                      </p>
                      <p>Minimum 8 characters</p>
                      <p>
                        At least one special character: . * @ ! # % & ( ) ^ ~
                      </p>
                      <p>At least one number</p>
                      <p>Can’t be the same as a previous password</p>
                    </div>
                  }
                  type="info"
                />
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default UserPassword;
