import { Pagination, Table, Tooltip } from "antd";
import { CiRead } from "react-icons/ci";

function UserTransactionTable(props: any) {
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{rowIndex + 1}</div>;
      },
    },
    {
      title: "userID",
      dataIndex: "userID",
      key: "userID",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "payment_amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "payment_id",
      dataIndex: "payment_id",
      key: "payment_id",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "payment_status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiRead
                size={20}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => props?.onView(item?.id)}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Table
          columns={columns}
          dataSource={props?.data}
          pagination={false}
          size="small"
        />
      </div>
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} users`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}
export default UserTransactionTable;
