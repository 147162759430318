import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";
import AdminTransactionScreen from "../admin/transaction";
import UserTransactionViewScreen from "../admin/transaction/view";
import UserData from "../admin/user";
import UserViewScreen from "../admin/user/view";
import SideBar from "./sideBar";
import "./styles.scss";
function AdminRoute() {
  const { Sider } = Layout;
  return (
    <Layout className="AdminRoute">
      <Sider width={260} className="AdminRoute-Sider">
        <SideBar />
      </Sider>
      <Layout>
        <div className="AdminRoute-box">
          <Routes>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/user" element={<UserData />} />
            <Route path="/userDetails/:id" element={<UserViewScreen />} />
            <Route
              path="/userTransaction"
              element={<AdminTransactionScreen />}
            />
            <Route
              path="/userTransaction/:id"
              element={<UserTransactionViewScreen />}
            />
          </Routes>
        </div>
      </Layout>
    </Layout>
  );
}

export default AdminRoute;
