import {
  Card,
  Drawer,
  Form,
  Input,
  Pagination,
  Select,
  TreeSelect,
  message
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import PageHeader from "../../components/pageHeaders";
import API from "../../config/api";
import { DELETE, POST } from "../../utils/apiCalls";
import UserChatDrawerTwo from "../component/chat/chat-drawer2";
import UserCard from "../component/userCard";
import WarningScreen from "../component/warning/warning";
import "./styles.scss";
const { Meta } = Card;

const UserSearch = () => {
  const [form] = useForm();
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(8);
  const [meta, setMeta] = useState<any>({});
  const [value, setValue] = useState<any>();
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);

  const user = useSelector((state: any) => state.User);

  let userData = user?.user?.data?.user_details?.id;
  let paymentStatus = user?.user?.data?.user_details?.payment_status;

  const getActvites = async () => {
    try {
      let obj = {
        query: form.getFieldValue("query"),
        gender: form.getFieldValue("gender"),
        page: page,
        take: take,
        value: value,
        id: Number(userData),
        payment: paymentStatus == "Paid" ? "Paid" : "",
      };

      let api = API.USER_LIST;
      const response: any = await POST(api, obj);

      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActvites();
  }, [page, take, value]);

  const treeData = [
    {
      title: "Age",
      value: "all-age",
      key: "0-0",
      children: [
        {
          title: "Between 18-25",
          value: "01",
        },
        {
          title: "Between 25-30",
          value: "02",
        },
        {
          title: "Between 30-40",
          value: "03",
        },
        {
          title: "Between 18-30",
          value: "04",
        },
        {
          title: "Between 25-40",
          value: "05",
        },
      ],
    },
    {
      title: "Height",
      value: "all-height",
      key: "0-1",
      children: [
        {
          title: "Between 100-150",
          value: "10",
        },
        {
          title: "Between 150-160",
          value: "20",
        },
        {
          title: "Between 160-180",
          value: "30",
        },
        {
          title: "Between 100-160",
          value: "40",
        },
        {
          title: "Between 150-180",
          value: "50",
        },
      ],
    },
    {
      title: "Weight",
      value: "all-weight",
      key: "0-2",
      children: [
        {
          title: "Between 30-40",
          value: "11",
        },
        {
          title: "Between 40-50",
          value: "22",
        },
        {
          title: "Between 60-70",
          value: "33",
        },
        {
          title: "Between 30-50",
          value: "44",
        },
        {
          title: "Between 40-70",
          value: "55",
        },
      ],
    },
  ];

  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };
  const onClose = () => {
    setOpen(false);
  };

  const Delete = async (id: any) => {
    try {
      const url = API.DISLIKE + id;
      const response: any = await DELETE(url);
      if (response.status) {
        setData((prevData: any) =>
          prevData.map((item: any) =>
            item?.id === id ? { ...item, liked: false } : item
          )
        );
        message.success("Disliked");
      }
    } catch (err: any) {
      console.log("err");
    }
  };

  const InterestPost = async (id: any) => {
    try {
      const url = API.POST_INTERST;
      const obj = {
        userId: parseInt(userData),
        likedId: parseInt(id),
      };
      const response: any = await POST(url, obj);
      if (response.status) {
        setData((prevData: any) =>
          prevData.map((item: any) =>
            item?.id === id ? { ...item, liked: true } : item
          )
        );
        message.success("Liked");
      }
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };

  return (
    <>
      {!paymentStatus ? (
        <>
          <PageHeader title={"Search"} backButton={true} />
          <WarningScreen />
        </>
      ) : (
        <>
          <PageHeader title={"Search"} backButton={true}>
            <Form form={form} onValuesChange={getActvites}>
              <div className="PageHeaderBox">
                <Form.Item name="value">
                  <br />
                  <TreeSelect
                    treeData={treeData}
                    value={value}
                    onChange={onChange}
                    treeCheckable
                    multiple={false}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    placeholder="Filter Here"
                    size="large"
                    style={{ width: "250px" }}
                  />
                </Form.Item>

                <Form.Item name={"query"} noStyle>
                  <Input
                    size="large"
                    allowClear
                    placeholder="Search Name And Email"
                    style={{ width: 250 }}
                    suffix={<CiSearch size={16} color="rgb(186, 186, 186)" />}
                  />
                </Form.Item>
                <Form.Item name={"gender"} noStyle>
                  <Select placeholder="Female/Male" size="large">
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Male">Male</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </PageHeader>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="screenBox">
              <UserCard
                data={data}
                Delete={(id: any) => Delete(id)}
                InterestPost={(id: any) => InterestPost(id)}
                setSelectedUserId={(item: any) => setSelectedUserId(item)}
                setOpen={setOpen}
              />
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className=""
              >
                <Pagination
                  total={meta?.total_count}
                  showSizeChanger={false}
                  onChange={(page) => {
                    setPage(page);
                    setTake(8);
                  }}
                />
              </div>

              {open && (
                <Drawer
                  width={500}
                  onClose={onClose}
                  open={open}
                  closable={false}
                >
                  <div className="userSearch-box1">
                    <UserChatDrawerTwo
                      onClose={onClose}
                      closable={false}
                      userData={userData}
                      id={selectedUserId?.conversaction?.id}
                      search={"search"}
                      selectedUserId={selectedUserId}
                      userName={user}
                    />
                  </div>
                </Drawer>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserSearch;
