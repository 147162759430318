import { Col, Container, Row } from "react-bootstrap";
import image from "../../../assets/images/watercolor-wedding-scene-flower-petals-background-with-purple-flowers_118124-61844.jpg";
import { Button } from "antd";
import "../styles.scss";

const BlockTwo = () => {
  return (
    // style={{ backgroundImage: `url(${image})` }}
    <Container>
      <Row className="g-0">
        <Col md={6}>
          <div className="blockTwo-Box1">
            <div className="blockTwo-Box2"></div>
          </div>
        </Col>

        <Col md={6}>
          <div className="blockTwo-Box3">
            <h1 className="heading_text">
              The perfect matrimonial site for a perfect match
            </h1>
            <br />
            <div className="blockTwo-Txt2">
              Welcome to our love story! We're Ziwaj, a pair of dreamers who
              found love in the most unexpected places. Our journey together has
              been filled with laughter, unforgettable memories, and countless
              moments that define our love. This website is our way of sharing
              our joy with you, our cherished friends and family. Join us as we
              take this next step in our adventure, hand in hand, and hearts
              brimming with love. Thank you for being a part of our happily ever
              after.
            </div>
            <br />
            <Button type="primary" style={{ width: "50%" }}>
              Register Now
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default BlockTwo;
