interface Prop {
  text: string;
  position: string;
  key?: number;
  image?: any;
}

const MessageBubble = ({ text, position, key, image }: Prop) => {
  return (
    <span key={key} className={`message-bubble-${position} ${position}`}>
      <span className="messageBubble-box1">
        {position === "left" && image}&nbsp;&nbsp;
        {text}
      </span>
    </span>
  );
};
export default MessageBubble;
