import { Col, Row } from "react-bootstrap";
import { FaFacebookSquare, FaInstagram, FaAppStore } from "react-icons/fa";
import { FiTwitter, FiYoutube } from "react-icons/fi";
import { BiLogoPlayStore } from "react-icons/bi";
import { Button } from "antd";
import LOGO from "../../config/Ziwaj.png";
import "./styles.scss";

function Footer() {
  return (
    <div className="Footer">
      <Row>
        <div className="Footer-itemCover">
          <Col xs={12} sm={3} className="Footer-Box1">
            <div>
              <img src={LOGO} className="Footer-logo" />
            </div>
            <div style={{ paddingLeft: 10 }}>
              <div>website</div>
              <div>mail id</div>
              <div>phone</div>
              <div style={{ marginTop: "10px" }}>
                <FaFacebookSquare size={20} /> &nbsp;
                <FaInstagram size={20} /> &nbsp;
                <FiTwitter size={20} /> &nbsp;
                <FiYoutube size={20} />
              </div>
            </div>
          </Col>
          <br />
          <Col className="Footer-Box2" xs={12} sm={3}>
            <div className="Footer-text1">Useful links</div> <br />
            <div>App Features</div>
            <div>About us</div>
            <div>Blog posts</div>
            <div>Pricing plans</div>
          </Col>
          <Col className="Footer-Box2" xs={12} sm={3}>
            <div className="Footer-text1">Product help</div> <br />
            <div>Privacy policy</div>
            <div>Terms & conditions</div>
            <div>Refund and Cancellation</div>
            <div>Support</div>
          </Col>
          <Col className="Footer-Box2" xs={12} sm={3}>
            <div className="Footer-text1">download app</div>
            <br />
            <BiLogoPlayStore size={30} style={{ marginRight: 30 }} />
            <FaAppStore size={30} />
            <br />
            <br />
            <Button className="Footer-Box-Btn2">Get Free Trial</Button>
          </Col>
        </div>
      </Row>
      <hr />
      <div style={{ textAlign: "center", fontSize: 14 }}>
        Copyright All Rights Reserved. Design & developed by Semikolen IT
        Solutions
      </div>
    </div>
  );
}

export default Footer;
