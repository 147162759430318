import { useNavigate } from "react-router-dom";
import { IoIosSearch, IoMdArrowBack, IoMdHeartEmpty } from "react-icons/io";
import { Container } from "react-bootstrap";
import {
  IoEyeOutline,
  IoListCircle,
  IoNotificationsOutline,
} from "react-icons/io5";
import { Modal } from "antd";
import { useState } from "react";
import { MdDashboard, MdOutlineAccountBalance } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { TbUsersPlus } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { PiStarThin } from "react-icons/pi";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { logout } from "../../redux/slices/userSlice";

import "./styles.scss";
import { useDispatch } from "react-redux";
function PageHeader(props: any) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    dispatch(logout(null));
    navigation("/");
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigationItems = [
    {
      label: "My Home",
      icon: <AiOutlineHome size={20} />,
      onClick: () => navigation("/user/userHomeScreen"),
    },
    {
      label: "Notification",
      icon: <IoNotificationsOutline size={20} />,
      onClick: () => navigation("/user/userNotificationScreen"),
    },

    {
      label: "Search",
      icon: <IoIosSearch size={20} />,
      onClick: () => navigation("/user/user-search"),
    },

    {
      label: "Message",
      icon: <MdDashboard size={20} />,
      onClick: () => navigation("/user/user-message"),
    },
    {
      label: "MyMatches",
      icon: <TbUsersPlus size={20} />,
      onClick: () => navigation("/user/user-myMatches"),
    },
    {
      label: "Interest",
      icon: <IoMdHeartEmpty size={20} />,
      onClick: () => navigation("/user/user-interest"),
    },

    {
      label: "Shortlist",
      icon: <PiStarThin size={20} />,
      onClick: () => navigation("/user/user-shortlist"),
    },
    {
      label: "My Profile",
      icon: <CgProfile size={20} />,
      onClick: () => navigation("/user/user-myProfile"),
    },
    {
      label: "Profile Visitors",
      icon: <IoEyeOutline size={20} />,
      onClick: () => navigation("/user/user-profileVisitors"),
    },
    {
      label: "My Account",
      icon: <MdOutlineAccountBalance size={20} />,
      onClick: () => navigation("/user/user-account"),
    },
    {
      label: "Logout",
      icon: <RiLogoutCircleRLine size={20} />,
      onClick: () => handleLogout(),
    },
  ];

  return (
    <Container>
      <div className="PageHeader">
        &nbsp;
        {props?.backButton ? (
          <div
            onClick={() => navigation(props?.goBack ?? -1)}
            className="PageHeader-back"
          >
            <IoMdArrowBack color="#fff" />
          </div>
        ) : null}
        <div className="PageHeader-Box">
          <div className="PageHeader-text1">{props?.title}</div>
          <div>{props?.title}</div>
        </div>
        <div style={{ flex: 1 }} />
        {props?.children ? <div>{props?.children}</div> : null} &nbsp;
        <div className="PageHeader-toggle-box1">
          <IoListCircle size={40} color="#875aa2" onClick={showDrawer} />
        </div>
      </div>
      <Modal visible={open} onCancel={onClose} centered footer={null}>
        {navigationItems.map((item: any) => {
          return (
            <div className="PageHeader-toggle-box2">
              <div className="PageHeader-toggle-box3">
                <p key={item.label} onClick={item.onClick}>
                  {item?.icon}&nbsp;&nbsp; {item.label}
                </p>
              </div>
            </div>
          );
        })}
      </Modal>
    </Container>
  );
}

export default PageHeader;
