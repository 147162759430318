import PageHeader from "../../components/pageHeaders";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import API from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import profile from "../../assets/images/user-avatar.png";
import UserChatDrawerTwo from "../component/chat/chat-drawer2";
import "./styles.scss";
import WarningScreen from "../component/warning/warning";
import { useNavigate } from "react-router-dom";
const UserMessageScreen = () => {
  const [form] = useForm();
  const navigation = useNavigate();
  const user = useSelector((state: any) => state.User);
  let userData = user?.user?.data?.user_details?.id;
  let paymentStatus = user?.user?.data?.user_details?.payment_status;
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(8);
  const [conversation, setConversation] = useState<any>([]);

  const getActvites = async () => {
    try {
      let obj = {
        query: form.getFieldValue("query"),
        page: page,
        take: take,
        user_id: Number(userData),
        payment: paymentStatus == "Paid" ? "Paid" : "",
      };

      let api = API.USER_MESSAGE_LIST_BY_USERID;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setConversation(response);
      } else {
        setConversation([]);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getActvites();
  }, [page, take]);

  return (
    <>
      {!paymentStatus ? (
        <>
          <PageHeader title={"Message"} backButton={true} />
          <WarningScreen />
        </>
      ) : (
        <>
          <PageHeader title={"Message"} backButton={true} />
          <br />
          <Container>
            <div className="userMessageScreen-box1">
              <Form form={form} onValuesChange={getActvites}>
                <Row>
                  <Col md={4}>
                    <div className="userMessageScreen-box2">
                      <div className="" style={{ textAlign: "center" }}>
                        <br />
                        <Form.Item name={"query"} noStyle>
                          <Input
                            allowClear
                            size="large"
                            placeholder="Search here"
                            style={{ width: 250 }}
                            suffix={
                              <CiSearch size={16} color="rgb(186, 186, 186)" />
                            }
                          />
                        </Form.Item>
                      </div>
                      <br />
                      <div className="formLabel">Chats</div>
                      {conversation?.data?.map((item: any) => {
                        let user =
                          item?.from_details?.id === userData
                            ? { ...item?.to_details, conversationid: item?.id }
                            : {
                                ...item?.from_details,
                                conversationid: item?.id,
                              };

                        return (
                          <div
                            className="userMessageScreen-box3"
                            onClick={() => setSelectedUserId(user)}
                          >
                            <div>
                              {user?.profileImage ? (
                                <img
                                  className="userMessageScreen-img"
                                  src={user.profileImage}
                                />
                              ) : (
                                <img
                                  className="userMessageScreen-img"
                                  src={profile}
                                />
                              )}
                            </div>
                            <div>&nbsp;&nbsp;{user?.firstName}</div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>

                  <Col md={4}>
                    <UserChatDrawerTwo
                      selectedUserId={selectedUserId}
                      userData={userData}
                      id={selectedUserId?.conversationid}
                      userName={user}
                      message={"message"}
                    />
                  </Col>
                  <Col md={4}>
                    <div className="userMessageScreen-box4">
                      <div
                        className="userMessageScreen-box5"
                        onClick={() =>
                          navigation("/user/user-profile", {
                            state: { data: { id: selectedUserId?.id } },
                          })
                        }
                      >
                        {selectedUserId?.profileImage ? (
                          <img
                            className="userMessageScreen-img2"
                            src={selectedUserId?.profileImage}
                          />
                        ) : (
                          <img
                            className="userMessageScreen-img2"
                            src={profile}
                          />
                        )}
                      </div>
                      <br />
                      <div className="userMessageScreen-txt1">
                        {selectedUserId?.firstName}
                      </div>
                      <hr />

                      <div>
                        <div>
                          {!selectedUserId ? "xxxxxxxxx" : "description"}
                        </div>
                        <div className="userMessageScreen-txt2">
                          {selectedUserId?.about}
                        </div>
                      </div>
                      <div>
                        <div> {!selectedUserId ? "xxxxxxxx" : "email"}</div>
                        <div className="userMessageScreen-txt2">
                          {selectedUserId?.email}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
          <br />
        </>
      )}
    </>
  );
};

export default UserMessageScreen;
