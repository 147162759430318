import { createSlice } from "@reduxjs/toolkit";
const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    user: {},
    steps: [false, false, false, false],
    auth: false,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.auth = true;
    },
    logout: (state, action) => {
      state.user = action.payload || {};
      state.auth = false;
    },
    update: (state, action) => {
      state.user = action.payload;
    },
    updateStep: (state, action) => {
      const stepIndex = action.payload;

      state.steps[stepIndex] = true;
    },
  },
});

export const { login, logout, update, updateStep } = AuthSlice.actions;
export default AuthSlice.reducer;
