const ThemeObj = {
  token: {
    colorPrimary: "#6e2e92",
    colorTextBase: "#3e2b21",
    colorInfo: "#875aa2",
    fontFamily: "DMSans-Bold",
  },
  components: {
    //   Popover: { borderRadius: 2, width: 400 },
    Radio: { colorPrimary: "#875aa2" },
    Checkbox: { colorPrimary: "#875aa2", colorBorder: "#875aa2" },
    //   Input: {
    //     lineWidth: 0.001,
    //     colorBgContainer: "#ffffff",
    //     lineWidthBold: 0.001,
    //     borderRadius: 5,
    //   },
    Button: {
      color: "#f2f2f2",
      fontFamily: "DMSans-Bold",
      fontSize: 18,
      // borderRadius: 10,
    },
  },
};

export default ThemeObj;
