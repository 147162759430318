import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  notification,
} from "antd";
import { SlArrowDown } from "react-icons/sl";
import relations from "../../RegistrationScreen/helper/relations.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../styles.scss";
const BlockOne = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.User);
  let logined_userid = user?.user?.data?.user_details;
  const onFinish = async (value: any) => {
    const { checkbox } = value;

    try {
      if (!checkbox) {
        notification.error({
          message: "Please accept the terms and conditions.",
        });
        return;
      }
      navigate("/landing", { state: { item: value } });
      notification.success({
        message: "Sucessfully Registered",
      });
    } catch (error) {
      notification.error({
        message: "Something Went Wrong!",
      });
    }
  };

  return (
    <div className="BlockOne_main">
      <Container>
        <Row>
          <Col lg={5} className="d-none d-lg-flex"></Col>
          <Col lg={7} md={12}>
            <div className="BlockOne-formCover">
              <div className="heading_text">Join No.1 Muslim Matrimonial</div>
              <br />
              <Card>
                <Form
                  onFinish={onFinish}
                  layout="vertical"
                  initialValues={{
                    prefix: "91",
                    creator: logined_userid?.creator,
                    firstName: logined_userid?.firstName,
                    phone: logined_userid?.phone,
                    gender: logined_userid?.gender,
                  }}
                >
                  <div className="cardHedding">Register Now - its free!</div>
                  <hr style={{ borderColor: "#875aa2" }} />
                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="formLabel2">Create Profile for</div>
                      <Form.Item
                        name="creator"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Profile",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          suffixIcon={<SlArrowDown />}
                          placeholder="Profile Creator"
                        >
                          {relations.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item.value}>
                                {item.value}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="formLabel2">Bride / Groom Name</div>
                      <Form.Item name="firstName">
                        <Input size="large" placeholder="Bride / Groom" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="formLabel2">Phone Number</div>
                      <Row>
                        <Col xs={4}>
                          <Form.Item
                            name="prefix"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Country Code",
                              },
                            ]}
                          >
                            <Select defaultValue={"+91"} size="large">
                              <Select.Option value="87">+87</Select.Option>
                              <Select.Option value="91">+91</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={8}>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please Type Your Phone Number",
                              },
                              {
                                pattern: /^\d{10}$/,
                                message:
                                  "Phone number must be exactly 10 digits",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Phone Number"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="formLabel2">Gender</div>
                      <Form.Item
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Gender",
                          },
                        ]}
                      >
                        <Radio.Group size="large">
                          <Radio value="Male" className="formLabel2">
                            Male
                          </Radio>
                          <Radio value="Female" className="formLabel2">
                            Female
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Item name="checkbox">
                        <div className="checkBoxContainer">
                          <Checkbox />
                          <div className="checkbox-text">
                            By clicking register free, you accept our{" "}
                            <a href="" style={{ textDecoration: "none" }}>
                              T&C{" "}
                            </a>
                            and{" "}
                            <a href="" style={{ textDecoration: "none" }}>
                              Privacy Policy
                            </a>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col md={6}></Col>
                    <Col xs={12} sm={6}>
                      <Button
                        block
                        className="customButton"
                        htmlType="submit"
                        type="primary"
                      >
                        Register Free
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlockOne;
