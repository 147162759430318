import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { SlArrowDown } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ImagePicker from "../../../components/imagePicker";
import API from "../../../config/api";
import { login, updateStep } from "../../../redux/slices/userSlice";
import { POST, PUT } from "../../../utils/apiCalls";
import relationCast from "../helper/relationCast.json";

const StepOne = ({ onNext, update, userDetails }: any) => {
  const user = useSelector((state: any) => state.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const { TextArea } = Input;
  const [loding, setLoding] = useState(false);
  const [toggle, toggleModal] = useState(false);
  const currentDate = moment().format("YYYY-MM-DD");
  const [image, setImage] = useState();

  let logined_userid = user?.user?.data;
  let step_register_create_data = logined_userid?.user_details;
  let step_finish = user.steps[0];

  const onFinish = async (value: any) => {
    try {
      setLoding(true);

      let _id = null;
      // if (userDetails?.userID) {
      //   _id = userDetails?.userID;
      if (step_register_create_data?.id) {
        _id = step_register_create_data?.id;
      } else if (logined_userid?.userID) {
        _id = logined_userid?.userID;
      }

      let url =
        update || step_finish
          ? API.USER_STEP_1_UPDATE + _id
          : API.REGISTER_STEP1;
      let obj = {
        firstName: value?.firstName,
        middleName: "",
        lastName: "",
        gender:
          location?.state?.item?.gender || step_register_create_data?.gender,
        dob: dayjs(value?.dob, "YYYY/MM/DD"),
        height: Number(value?.height),
        weight: Number(value?.weight),
        maritalStatus: value?.maritalStatus,
        physicalStatus: value?.physicalStatus,
        regional_caste: value?.regional_caste,
        about: value?.about,
        qualification: "",
        job: "",
        profileImage: image || "",
        email: value?.email,
        phone:
          location?.state?.item?.phone ||
          userDetails?.user_details?.phone ||
          step_register_create_data?.phone,
        password: value?.password,
        creator:
          location?.state?.item?.creator ||
          userDetails?.user_details?.creator ||
          step_register_create_data?.creator,
        type: "user",
        status: true,
        profileStatus: 0,
        age: Number(value?.age),
        email_verify: 1,
      };

      let res: any;
      if (update || step_finish) {
        res = await PUT(url, obj);
      } else {
        res = await POST(url, obj);
      }
      if (res.status) {
        setLoding(false);
        notification.success({
          message: res?.message,
        });

        dispatch(login(res?.data));

        dispatch(updateStep(0));

        onNext();
      } else {
        setLoding(false);
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      setLoding(false);
      notification.error({
        message: "Not Create",
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName:
        userDetails?.user_details?.firstName ||
        step_register_create_data?.firstName ||
        location?.state?.item?.firstName,
      middleName:
        userDetails?.user_details?.middleName ||
        step_register_create_data?.middleName,
      lastName:
        userDetails?.user_details?.lastName ||
        step_register_create_data?.lastName,
      gender:
        userDetails?.user_details?.gender || step_register_create_data?.gender,
      dob:
        dayjs(userDetails?.user_details?.dob) || step_register_create_data?.dob,
      height:
        userDetails?.user_details?.height || step_register_create_data?.height,
      weight:
        userDetails?.user_details?.weight || step_register_create_data?.weight,
      maritalStatus:
        userDetails?.user_details?.maritalStatus ||
        step_register_create_data?.maritalStatus,
      physicalStatus:
        userDetails?.user_details?.physicalStatus ||
        step_register_create_data?.physicalStatus,
      about:
        userDetails?.user_details.about || step_register_create_data?.about,
      profileImage:
        userDetails?.user_details?.profileImage ||
        step_register_create_data?.profileImage,
      age: userDetails?.user_details?.age || step_register_create_data?.age,
      regional_caste:
        userDetails?.user_details?.regional_caste ||
        step_register_create_data?.regional_caste,
      email:
        userDetails?.user_details?.email || step_register_create_data?.email,
    });
  }, []);

  const handleImageUpload = (imageData: any) => {
    setImage(imageData);
  };

  return (
    <>
      <br />
      <Card>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          scrollToFirstError={true}
        >
          <Row>
            <Col md={6}>
              <Col md={12}>
                <div className="formLabel">Full Name</div>
                <Form.Item
                  name={"firstName"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Full Name",
                    },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <div className="formLabel">Religion/Caste</div>
                <Form.Item
                  name={"regional_caste"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Religion/Caste",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<SlArrowDown />}
                    style={{ width: "100%" }}
                    placeholder="Caste"
                  >
                    {relationCast.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Col>
            <Col md={6}>
              <div
                className="StepOne_box1"
                onClick={() => {
                  toggleModal(true);
                }}
              >
                <br />

                {image ? (
                  <div className="StepOne_box2">
                    <img
                      src={image}
                      className="upload_Image"
                      alt={`Uploaded Image`}
                    />
                  </div>
                ) : userDetails?.user_details?.profileImage ||
                  step_register_create_data?.profileImage ? (
                  <div className="StepOne_box2">
                    <img
                      src={
                        userDetails?.user_details?.profileImage ||
                        step_register_create_data?.profileImage
                      }
                      className="upload_Image"
                      alt={`Uploaded Image`}
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CgProfile size={80} color="grey" />
                  </div>
                )}
                <br />
                <div className="Registration-txt">
                  {image ? "Change Profile" : "Upload Profile"}
                </div>
                <br />
              </div>
            </Col>

            {update ? (
              ""
            ) : (
              <Col md={6}>
                <div className="formLabel">Email</div>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Email Id",
                    },
                  ]}
                >
                  <Input placeholder="Email" type="email" />
                </Form.Item>
              </Col>
            )}

            {update || step_finish ? (
              ""
            ) : (
              <Col md={6}>
                <div className="formLabel">Password</div>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
            )}

            <Col md={6}>
              <div className="formLabel">Height (cm)</div>
              <Form.Item
                name={"height"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Height",
                  },
                ]}
              >
                <Input placeholder="Height" type="number" />
              </Form.Item>
            </Col>
            {update || step_finish ? (
              ""
            ) : (
              <Col md={6}>
                <div className="formLabel">Confirm Password</div>
                <Form.Item
                  name={"confirmPassword"}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </Col>
            )}
            <Col md={6}>
              <div className="formLabel">Age</div>
              <Form.Item name="age">
                <Input placeholder="Enter Your Age" type="number" />
              </Form.Item>
            </Col>

            <Col md={6}>
              <div className="formLabel">Weight (cm)</div>
              <Form.Item
                name={"weight"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Weight",
                  },
                ]}
              >
                <Input placeholder="weight" type="number" />
              </Form.Item>
            </Col>

            <Col md={6}>
              <div className="formLabel">Physical Status</div>
              <Form.Item
                name={"physicalStatus"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Physical Status",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="Normal" className="formLabel">
                    Normal
                  </Radio>
                  <Radio value="Disability" className="formLabel">
                    With Disability
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={6}>
              <div className="formLabel">Marital Status</div>
              <Form.Item
                name={"maritalStatus"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Marital Status",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="Unmarried" className="formLabel">
                    Unmarried
                  </Radio>
                  <Radio value="Widower" className="formLabel">
                    Widower
                  </Radio>
                  <Radio value="Divorcee" className="formLabel">
                    Divorcee
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={6}>
              {" "}
              <div className="formLabel">Date of Birth</div>
              <Form.Item
                name={"dob"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Date of Birth",
                  },
                ]}
              >
                <DatePicker placeholder={currentDate} />
              </Form.Item>
            </Col>

            <Col md={6}>
              <div className="formLabel">About the Candidate</div>
              <Form.Item
                name={"about"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter About the Candidate",
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            {update ? <Col sm={6} xs={12}></Col> : ""}

            <Col sm={6} xs={12}></Col>
            <Col sm={3} xs={12}>
              {!update && (
                <Button size="large" block onClick={() => navigate("/")}>
                  Back
                </Button>
              )}
            </Col>
            <Col sm={3} xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loding}
                size="large"
              >
                {update ? "Update" : "Next"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <br />
      <br />
      <ImagePicker
        onImageUpdated={(data: any) => handleImageUpload(data)}
        open={toggle}
        modalClose={() => toggleModal(false)}
      />
    </>
  );
};

export default StepOne;
